import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage} from "firebase/storage";
import { getAuth} from 'firebase/auth';


const firebaseConfig = {
    apiKey: "AIzaSyAwN5fFXnu6p5vYUTirodlWMMWO_5oE6ak",
    authDomain: "socialcity-702b9.firebaseapp.com",
    projectId: "socialcity-702b9",
    storageBucket: "socialcity-702b9.appspot.com",
    messagingSenderId: "530776610457",
    appId: "1:530776610457:web:cc99a477e0da5661e64a08",
    measurementId: "G-1T0GVDVMQY"
    };

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);



  

