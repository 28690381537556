import style from "./style.module.css";
import { FaPaperPlane } from 'react-icons/fa';
import { useState, useRef, useEffect } from "react";
import loading from "../../Imgs/LoadSnail.gif";
import React from "react";
import { obterToken } from "../../global";
import apiUrl from "../../global";
import imgpost from "../../Imgs/addimage100px.png"
import axios from 'axios';
import { FaArrowLeft } from 'react-icons/fa';
import ShowUserLittleProfile from '../ShowUserLittleProfile';

function ExibirVideoSelecionado({ videoSrc }) {
    const videoRef = useRef(null);
    return (
      <div className={style.videoContainer}>
        <video 
          ref={videoRef}
          src={videoSrc} 
          controls={false} // Remove os controles padrão
          style={{ width: '100%', height: '100%',objectFit: 'cover' }} // Estilo opcional para ajustar o tamanho
        />
      </div>
    );
}

export default function Index({user, onClickFadeBackground}){
    const [isLoadingModal, setIsLoadingModal] = useState(false); //true? está enviando shorts, telinha de carregando
    const [error, setError] = useState("");
    const [descricaoPostagem, setDescricaoPostagem] = useState(""); //guarda texto da postagem
    const [videoSrc, setVideoSrc] = useState(""); //Para exibir o vídeo
    const [publico, setPublico] = useState("seguidores");
    const [modalNumero, setModalNumero] = useState("modal1"); //Controla qual modal vai aparecer na tela
    const meufile = useRef();
    // const podePostarGlobal = verificaPodePostarGlobal();

    let maxSizeInMB; //Peso máximo do video em mb, pareado com back e multer
    let maxDuration; //Duração máxima em segundos, pareado com back 
    const minDuration = 5; // Duração mínima em segundos
    console.log(user);
    if(user.claims=="moderador" || user.claims=="admin"){
      maxSizeInMB=400; 
      maxDuration=500;
    }else{
      if(user.pverificado==1){
        maxSizeInMB=140;
        maxDuration=240;
      }
      else{
        maxSizeInMB = 75; 
        maxDuration=120;
      }
    }
    



    // function verificaPodePostarGlobal(){
    //   const creationTime = new Date(user.creationTime); // Converte para Date
    //   const currentTime = new Date(); // Data e hora atuais
    
    //   // Calculando a diferença em milissegundos e convertendo para dias
    //   const diffTime = Math.abs(currentTime - creationTime);
    //   const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    //   const limite = 3; //PS: PAREADO COM O BACK
    //   if(diffDays<limite){
    //     return false;
    //   }else{
    //     return true;              
    //   }
    // }
    const clearFileInput = () => {
        if (meufile.current) {
            meufile.current.value = ''; // Limpa o campo de arquivo
        }
        setVideoSrc(false);
    };
    function handlePostError(error) {
        if (error.response && error.response.data) {
          const { code, message } = error.response.data;
          setError(message || 'Ocorreu um erro inesperado.');
        } else {
          setError('Ocorreu um erro inesperado.');
        }
    }
    async function enviarShorts(){
        if(!videoSrc){setError("Selecione um vídeo para continuar"); return;}
        const formData = new FormData();
        formData.append('descricao', descricaoPostagem);
        formData.append('publico', publico);
        formData.append('video', meufile.current.files[0]);
        try {
            setIsLoadingModal(true);
            // Obtém o token de autenticação
            const token = await obterToken();
    
            // Faz a requisição POST para a API
            
            const response = await axios.post(`${apiUrl}/criarShorts`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            setVideoSrc(''); // Limpa o vídeo exibido
            setDescricaoPostagem(''); // Limpa a descrição
            window.location.reload();
        } catch (erro) {
            handlePostError(erro);
        }finally{
            setIsLoadingModal(false);
        }


    }
    function getRatioString(ratio) {
        // Função auxiliar para obter a string da proporção
        const ratios = {
            [9/16]: '9:16',
            [2/3]: '2:3',
            [1/1]: '1:1',
            [3/4]: '3:4',
            [4/5]: '4:5',
            /*[16/9]: '16:9', desktop
            [3/2]: '3:2'*/
        };

        return ratios[ratio] || 'Desconhecida';
    }
    function verificaDimensao(file, allowedAspectRatios = [9/16, 2/3, 4/5, 1/1, 3/4], tolerance = 0.05) {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.src = URL.createObjectURL(file);
    
            video.onloadedmetadata = () => {
                const width = video.videoWidth;
                const height = video.videoHeight;
                const aspectRatio = width / height;
    
                // Verifica se o vídeo carregou corretamente
                if (width === 0 || height === 0) {
                    reject(new Error('Erro ao carregar o vídeo'));
                    return;
                }
    
                // Encontra a proporção mais próxima da lista de proporções permitidas
                const ratioString = allowedAspectRatios.map(ratio => {
                    const minRatio = ratio - tolerance;
                    const maxRatio = ratio + tolerance;
                    return {
                        ratio,
                        ratioString: getRatioString(ratio)
                    };
                }).find(({ ratio, ratioString }) => aspectRatio >= ratio - tolerance && aspectRatio <= ratio + tolerance)?.ratioString || 'Desconhecida';
    
                // Define se o vídeo é válido ou não com base na proporção
                const isValid = ratioString !== 'Desconhecida';
    
                resolve({ isValid, ratio: ratioString });
    
                // Libere o objeto URL após a verificação
                URL.revokeObjectURL(video.src);
            };
    
            video.onerror = (error) => {
                reject(error);
            };
        });
    }
    async function handleFileChange(event) {
        const file = event.target.files[0];

        const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

        setError("");

        if (!file) { //Se n tem video (caso o usuario clique pra adc video e feche)
            return; //Então retorna e n faça nada.
        }

        if (!file.type.startsWith('video/')) { // Verifica se o arquivo é um vídeo
            //alert('O arquivo selecionado não é um vídeo.');
            setError('O arquivo selecionado não é um vídeo.');
            clearFileInput();
            return;
        }
    
        if (file.size > maxSizeInBytes) {
            setError(`Arquivo muito pesado. O tamanho máximo permitido é ${maxSizeInMB} MB.`);
            clearFileInput();
            return;
        }

        const result = await verificaDimensao(file);
        if(result.isValid==false){
            setError(`Vídeo possúi proporções inadequadas, favor escolher outro.`);
            clearFileInput();
            return; 
        }

         // Cria um URL temporário para o vídeo e atualiza o estado
         const videoUrl = URL.createObjectURL(file);

        // Cria um elemento de vídeo para verificar a duração
        const video = document.createElement('video');
        video.src = videoUrl;
        
        //Verifica duração
        video.addEventListener('loadedmetadata', () => {
            const duration = video.duration;
    
            if (duration < minDuration || duration > maxDuration) {
                setError(`A duração do vídeo deve estar entre ${minDuration} e ${maxDuration} segundos.`);
                clearFileInput();
                //alert(`A duração do vídeo deve estar entre ${minDuration} e ${maxDuration} segundos.`);
                URL.revokeObjectURL(videoUrl); // Libera o URL temporário
                return;
            }
    
            // Se a duração estiver dentro dos limites, atualiza o estado
            setVideoSrc(videoUrl);
            setModalNumero("modal2");
        });


     
    }
    function primeiroModal(){
      return(
        <div className={style.flexContainer}> {/*Flex column*/}

          <div className={style.flexSuperior}> {/*Parte superior*/}
            <div className={style.modalHeader}>
              <FaArrowLeft size={24} className={style.iconVoltar} onClick={()=>onClickFadeBackground()}/>
              <p>Criar novo Shorts</p>
              <span/>
            </div>
            <hr className={style.divisaoHr}/>
          </div>

          <div className={style.flexInferiorModal1}>{ /*Parte inferior, flex column center*/}
              <img src={imgpost} className={style.imgPost}/>
              <button onClick={() => meufile.current.click()} className={style.buttonSelectMidia}>Selecionar vídeo</button>
              <p className={style.error}>{error}</p> 
              {!error&&<p>
                Entre {minDuration} e {maxDuration} segundos
                {user.claims=="moderador" &&
                 " (MOD/ADMIN)"
                }
                
              </p>}
          </div>

        </div>
        )
    }
    function segundoModal(){
      return(
        <div className={style.flexContainer}> {/*Flex column*/}

          <div className={style.flexSuperior}> {/*Parte superior*/}
            <div className={style.modalHeader}>
              <FaArrowLeft size={24} className={style.iconVoltar} onClick={()=>setModalNumero("modal1")}/>
              <p>Criar novo Shorts</p>
              <span onClick={isLoadingModal ? null : enviarShorts} className={style.iconSend}><FaPaperPlane /></span>
            </div>
            <hr className={style.divisaoHr}/>
          </div>

          <div className={style.flexInferiorModal2}>{ /*Parte inferior, flex column center*/}
            {isLoadingModal ? 
                (
                <div className={style.loadingImg}>
                    <p>Você pode continuar usando a plataforma enquanto é feito upload de sua postagem.</p>
                    <img src={loading} alt="Loading" />
                </div>
                ) 
                : 
                (
                <React.Fragment>
                    {<ShowUserLittleProfile user={user} naoclicavel={true}/>}
                    <textarea className={style.textAreaDescricao} name="descricao" placeholder='Máximo 2000 caracteres' maxLength="2000" value={descricaoPostagem} onChange={e => setDescricaoPostagem(e.target.value)}></textarea>
                    <p className={style.error}>{error}</p>
                    <div className={style.buttonContainer}>
                      <select className={style.escolherPublico} value={publico} onChange={ (e) => setPublico(e.target.value) }>
                        <option value="seguidores">Seguidores, amigos e perfil</option>
                        {/* {podePostarGlobal? <option value="global">Global</option>: <option value="global" disabled>Global - após 3 dias da criação da conta.</option>} */}
                        <option value="global">Global</option>
                      </select>
                    </div>
                    <div>
                      <span onClick={() => meufile.current.click()}>
                        {videoSrc && <ExibirVideoSelecionado videoSrc={videoSrc}/>}
                      </span>
                    </div>
                </React.Fragment>
            )}
          </div>
          
        </div>
        )
    }



    return(
        <div className={style.modalShorts}>
          <input type="file" ref={meufile} style={{ display: 'none' }} accept="video/*" onChange={handleFileChange}/>                    
          {modalNumero=="modal1"?
          primeiroModal()
          :
          segundoModal()
        }
        </div>
    )
}