import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react';
import Login from './Login/index';
import Home from './Home';
import UserProfile from './UserProfile';
import { getUser } from './global';
import LoadingScreen from "./LoadingScreen";
import Shorts from "./Home/MagnetShorts";
import Moderador from "./Home/Moderador";
import { handleLogout } from './global';
import { calcularTempoDecorrido, obterToken } from './global';
import style from "./App.module.css";
import apiUrl from './global';
import Privacidade from "./Paginas/PoliticaPrivacidade";
import Contribuinte from "./Home/Ajudante/index.js";
import Mensagens from "./Home/Mensagens/index.js";
import io from 'socket.io-client';
function App() {
  const [modalBanirHandler, setModalBanirHandler] = useState(false);
  const [modalExcluidoHandler, setModalExcluidoHandler] = useState(false);
  const [userAuth, setUserAuth] = useState(); 
  const [user, setUser] = useState();
  const [userTemp, setUserTemp] = useState(); //Guarda usuario temporariamente, pra caso o usuario quera des-excluir
  const [gotAllDataINeed, setGotAllDataINeed] = useState(false); //True quando pega todos os dados do usuario ou qd n tem usuario
  const [listenerWorking, setListenerWorking] = useState(true); //Controle pra qd criar usuario, n executar listener antes de inserir os dados no sql
  const [errorExcluido, setErrorExcluido] = useState("");

  useEffect(() => {
    if(listenerWorking){
      //userAuth, só contem: email, uid, emailVerified. São dados atualizados por listener. 
      const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, (usuario) => {
        if (usuario) { //Se mudou algo
          setUserAuth(usuario); //Coloca no useState
        } else { //Caso não exista usuario (deslogado)
          setGotAllDataINeed(true); //Usuario deslogado, pode continuar dando setGotAllDataINeed
          setUserAuth(null); //Limpando
          setUser(null); //E limpa esse tb
        }
      });
      return () => unsubscribe();
    }
  }, []);

  //O restante dos dados são obtidos no mysql (nome, sobrenome, etc) toda vez q é atualizado a págna, no codigo abaixo:
  useEffect(() => { //UseEffects pra pegar Usuario atualizado
    async function pegarUsuario() {
      try {
        const dadosusuario = await getUser("pessoal");

        let todosClaims = dadosusuario.claims;
        let claims;
        let pverificado;
        
        if (todosClaims && todosClaims.admin) 
          claims = "admin"
        else if (todosClaims && todosClaims.moderador) 
          claims = "moderador";
        else 
          claims = "";
        
        if(dadosusuario){ //Nem precisa de else, se n tiver usuario o onAuth do app desloga
          const updatedUser = {
            creationTime: userAuth.metadata.creationTime,
            email: userAuth.email, 
            uid: userAuth.uid,
            nome: dadosusuario.nome,
            userBanido: dadosusuario.userBanido,
            userExcluido: dadosusuario.userExcluido,
            sobrenome: dadosusuario.sobrenome,
            username: dadosusuario.username,
            sexo: dadosusuario.sexo?dadosusuario.sexo:"",
            seguidorCount: dadosusuario.seguidorCount,
            seguidoCount: dadosusuario.seguidoCount,
            postCount: dadosusuario.postCount,
            profile_image: dadosusuario.profile_image,
            dataNascimento: dadosusuario.dataNascimento?dadosusuario.dataNascimento:"",
            isUserVerified: userAuth.emailVerified, 
            ultimaNotificacao: dadosusuario.ultimaNotificacao,
            providerId: dadosusuario.providerId,
            pverificado: dadosusuario.pverificado,
            claims: claims
          };
          if(dadosusuario.userBanido==false && !dadosusuario.userExcluido){
            setUser(updatedUser);
            console.log(updatedUser);
          }else{ //Nao vai ter usuario nem gotalldataIneed se ele tiver banido
            if(dadosusuario.userBanido==true){
              setModalBanirHandler(true);
              return;
            }else if(dadosusuario.userExcluido){
              console.log("Chega aq222");
              setUserTemp(updatedUser);
              setModalExcluidoHandler(true);
            }
          }
          
        }
      } catch (error) {
        alert("Erro ao pegar os dados do usuário");
        handleLogout();
        console.error("Erro ao pegar os dados do usuário:", error);
      }
      setGotAllDataINeed(true);
    }
    if(userAuth && listenerWorking){
      pegarUsuario();
    }
    
  }, [userAuth,listenerWorking]);
  
  //Configurando socket
  const [socket, setSocket] = useState(null);
  useEffect(() => {
    const setupSocket = async () => {
      const token = await obterToken(); // Função para obter o token de autenticação

      const newSocket = io(window.location.href.startsWith("http://localhost") ? "http://localhost:3000" : "https://hevelim.com", {
        auth: { token },
        transports: ['websocket'],
        reconnectionAttempts: Infinity, // Tenta reconectar até 5 vezes
        reconnectionDelay: 10000 // Delay de 5 segundos entre as tentativas
      });

      newSocket.on('reconnect_failed', () => {
        alert('Não foi possível reconectar com o Hevelim após 5 tentativas. Verifique se você está com internet.');
      });

      newSocket.on('connect', () => {
        console.log('Conectado ao servidor via Socket.IO');
      });

      newSocket.on('disconnect', () => {
        console.log('Desconectado do servidor');
      });

      setSocket(newSocket);
    };

    if(user && gotAllDataINeed){
      setupSocket();
    }

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [user,gotAllDataINeed]);
  

  function Modalbanido(){
    function handleClick(){
      handleLogout();
      setModalBanirHandler(false);
    }
    
    return(
      <div className={style.modalExcluirBanir}>
        <div className={style.modalParteUsada}>
          <p>
          Sua conta foi banida devido a violação das nossas políticas. 
          Se você acredita que isso foi um engano ou se precisar de assistência, por favor,
          entre em contato com o suporte para mais informações.
          Se isso é um erro, não demoraremos para concerta-lo. Agradecemos a compreensão.
          </p>
          <button className={style.sairBotao} onClick={()=>handleClick()}>Sair</button>
        </div>     
      </div>
    )
  }

  function ModalExcluido(){  
    function handleSair(){
      setModalExcluidoHandler(false);
      setUserTemp("");
      handleLogout();
    }
    
    async function handleCancelarExclusao(){
      try { 
        const token = await obterToken();
        
        const response = await fetch(`${apiUrl}/cancelarExclusao`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          },
        });
        
        const data = await response.json();
        if (!response.ok) {
          setErrorExcluido(data.message || 'Erro desconhecido');
        }else{
          setUser(userTemp);
          setModalExcluidoHandler(false);
        }
    
        return data; // Dados 
      } catch (error) {
        throw new Error(error.message || 'Erro desconhecido');
      }
    }

    return(
      <div className={style.modalExcluirBanir}>
        <div className={style.modalParteUsada}>
            <p className={style.mensagemParagrafo}>
              Sua conta foi configurada para autoexclusão e será excluída automaticamente em 20 dias.
              Durante esse período, caso mude de ideia, você pode recuperá-la clicando no botão "Cancelar exclusão" abaixo.
              Seu perfil está atualmente oculto, incluindo fotos, vídeos, publicações e comentários.
              Se alguém tentar acessar seu perfil (ex: hevelim.com/username), verá uma mensagem informando que o perfil está desativado ou é inválido.
              <br/>Tempo decorrido: {calcularTempoDecorrido(userTemp.userExcluido)}.
            </p>
            <span className={style.spanBotoes}>
              <button onClick={()=>handleSair()} className={style.sairBotao}>Sair</button>
              <button onClick={()=>handleCancelarExclusao()} className={style.botaoCancelarExclusao}>Cancelar exclusão</button>
            </span>
        </div>
      </div>     
    )
  }

  return (
    <>
      <Router>
        {modalBanirHandler?<Modalbanido/>:
        modalExcluidoHandler?<ModalExcluido/>:
        <>
          <Routes>
            <Route path="/privacidade" element={<Privacidade/>}/>
          
              <Route path="/" element={
                gotAllDataINeed?
                  (user && user.isUserVerified? 
                    <Home user={user} setUser={setUser} socket={socket}/>
                    :
                    <Login setListenerWorking={setListenerWorking} user={user ? user : undefined}/>
                  )
                :
                <LoadingScreen/>
              }/>

              <Route path="/:username" element={
                gotAllDataINeed?
                  ((user && user.isUserVerified)? <UserProfile user={user} setUser={setUser}/>:<Login setListenerWorking={setListenerWorking}/>)
                :
                <LoadingScreen/>
              }/>

              <Route path="/shorts" element={
                gotAllDataINeed?
                  ((user && user.isUserVerified)? <Shorts user={user} setUser={setUser}/>:<Login setListenerWorking={setListenerWorking}/>)
                :
                <LoadingScreen/>
              }/>

              <Route path="/mensagens" element={
                gotAllDataINeed?
                  ((user && user.isUserVerified)? <Mensagens user={user} socket={socket} setUser={setUser}/>:<Login setListenerWorking={setListenerWorking}/>)
                :
                <LoadingScreen/>
              }/>

              <Route path="/moderador" element={
                gotAllDataINeed?
                    ((user && user.isUserVerified)? 
                        user.claims=="admin" || user.claims=="moderador"?
                          <Moderador user={user} setUser={setUser}/>
                        : 
                          <Home socket={socket} user={user} setUser={setUser}/> //Não tem permissão mas tá logado, vai pro home
                          
                    :
                    <Login setListenerWorking={setListenerWorking}/>)
                :
                <LoadingScreen/>
              }/>

              <Route path="/ajudante" element={
                gotAllDataINeed?
                    ((user && user.isUserVerified)? 
                        user.claims=="admin" || user.claims=="moderador"?
                          <Contribuinte user={user} setUser={setUser}/>
                        : 
                          <Home socket={socket} user={user} setUser={setUser}/> //Não tem permissão mas tá logado, vai pro home
                          
                    :
                    <Login setListenerWorking={setListenerWorking}/>)
                :
                <LoadingScreen/>
              }/>
            
          </Routes>
        </>}
      </Router>
   
    </>
  );
}

export default App;
