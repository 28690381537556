import style from "./style.module.css";
import { FaTimes } from 'react-icons/fa';
import { getSeguidores,handleSeguirOuNao } from "../../global";
import { useEffect, useState} from "react";
import ShowUserLittleProfile from "../../Home/ShowUserLittleProfile";
import { FaArrowLeft } from 'react-icons/fa'; // Importa o ícone de seta

export default function ModalEditarPerfil({user,setModalHandler, tipo,owner}) {
    const [seguidores, setSeguidores] = useState([]);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [atingiuFim, setAtingiuFim] = useState(false);
    const [inputProcurar, setInputProcurar] = useState(""); //Input do procurar
    const [loadMore, setLoadMore] = useState(true); //Handler se deve ou n puxar seguidores
    const [modoExibicao, setModoExibicao] = useState("padrao");
    function normalizeString(str) {
        return str
            .normalize("NFD") // Decompor caracteres acentuados
            .replace(/[\u0300-\u036f]/g, "") // Remover acentos
            .replace(/[^a-zA-Z0-9_]/g, "") // Remover espaços e símbolos (exceto underline)
            .replace(/\s+/g, "") // Remover espaços
            .toLowerCase(); // Opcional: converter para minúsculas
    }

    useEffect(()=>{ 
        async function fetchData(){ 
            /*
            Se procurar.trim=="" qr dizer q ele n qr procurar nada, então
            se tiver no modo procurar (procurarHandler==true), torna o modo procurar false.

            AO entrar no modo procurar, limpa lista e limpa offset, e só sai do modo procurar
            se procurar.trim == "".

            Obviamente começa fora do modo procurar.
            */
            try{
                let seguidores;
                seguidores = await getSeguidores(user.uid,tipo, limit, offset,null); 
                setAtingiuFim(false); //Sempre reseta atingiu fim
                setOffset(offset+limit); //Atualiza offset
                const novosSeguidores = seguidores.users || [];
                setSeguidores(prevSeguidores => {
                    return [...prevSeguidores, ...novosSeguidores];
                });
                if(novosSeguidores.length<limit){
                    setAtingiuFim(true);
                }
            }catch(error){
               console.log("Erro");
            }finally{
                setLoadMore(false);
            }
        }
        async function fetchDataSearch(){
            console.log("OKkkk");  
            try{
                const inputNormalizado = normalizeString(inputProcurar);
                const seguidores = await getSeguidores(user.uid,tipo, limit, offset,inputNormalizado); 
                setAtingiuFim(false); //Sempre reseta atingiu fim
                setOffset(offset+limit); //Atualiza offset
                const novosSeguidores = seguidores.users || [];
                setSeguidores(prevSeguidores => {
                    return [...prevSeguidores, ...novosSeguidores];
                });
                if(novosSeguidores.length<limit){
                    setAtingiuFim(true);
                }
            }catch(error){
               console.log("Erro");
            }finally{
                setLoadMore(false);
            }
        }
        if (loadMore && modoExibicao=="padrao"){
            fetchData(); 
        }

        if(loadMore && modoExibicao=="search" && inputProcurar.trim()!=""){
            fetchDataSearch();
        }

    },[loadMore])


    function imprimirSeguidores() {
        async function handleDeleteSeguindo(uidDeQuemVcSegue){
            const result = handleSeguirOuNao(uidDeQuemVcSegue);
            if(result){ //Deu certo, retirando ele da lista agora
                setSeguidores((prevSeguidores) =>
                    prevSeguidores.filter(seguidor => seguidor.user.uid !== uidDeQuemVcSegue)
                );
            }
        }
        return (
            <div>
                {seguidores && seguidores.map((seguidor, index) => (
                    <div className={style.containerUsuarios} key={index}>
                        <ShowUserLittleProfile 
                            user={seguidor.user} 
                        />
                        {tipo=="ativo" && owner &&
                            <div className={style.botaoRemover}>
                                <button onClick={()=>handleDeleteSeguindo(seguidor.user.uid)}>Remover</button>
                            </div>
                        }
                        
                    </div>
                ))}
            </div>
        );
    }

    function handleProcurar(){ //Ao clicar no botao de procurar
        if(inputProcurar==""){ //Se input tiver vazio e ele pressionou o botão, qr load normal
            setOffset(0);
            setModoExibicao("padrao");
            setSeguidores([]);
        }else{ //Tem algo no input
            setModoExibicao("search");
            setOffset(0);
            setSeguidores([]);
        }
        setLoadMore(true); 
    }

    function handleInputKeyDown(e){ //Ao pressionar enter no input
        if (e.key === 'Enter') {
            handleProcurar();   //Executar codigo do botão
        }
    }
    return (
        <div className={style.container}>
            <div className={style.fadeBackground} onClick={()=>setModalHandler(false)}></div>
            <div className={style.modal}>
                <div className={style.titleContainer}>
                    <div className={style.title}>
                        <FaArrowLeft style={{fontSize:'1.2em', cursor:"pointer"}}  onClick={()=>setModalHandler(false)}/>
                        {tipo=="passivo"?<p>Seguidores</p>:<p>Seguindo</p>}
                        <span></span>
                    </div>
                </div>
                <div className={style.conteudo}>
                    <div className={style.pesquisa}>
                        <input  onKeyDown={handleInputKeyDown} type="text" value={inputProcurar} onChange={(e)=>setInputProcurar(e.target.value)} className={style.searchInput} placeholder="Pesquisar"/>
                        <button className={style.searchButton} onClick={()=>handleProcurar()}>Procurar</button>
                    </div>
                    {seguidores && !loadMore && imprimirSeguidores()}
                    {loadMore && <p>Carregando..</p>}
                    {!loadMore && seguidores.length==0 && <p>Nenhum usuário encontrado</p>}
                    {!loadMore && !atingiuFim && <p className={style.cursorPointer} onClick={()=>setLoadMore(true)}>Mostrar mais</p>}
                </div>
            </div>
        </div>
    );
}
