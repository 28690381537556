import style from "./ModalDeletePostagem.module.css";
import {deletarPostagemGlobal} from "../../../global";
import { useState } from "react";
import { handleSeguirOuNao } from "../../../global";




export default function ModalDeletePostagemComp({ postagemSelecionadaDados,setListaPostagens,user,setModalDeletePostagem }) {
    const postagem = postagemSelecionadaDados;
    const postador = postagem.postador;
    const [option, setOption] = useState("inicio");
    // Função para deletar a postagem e todas as imagens associadas
    async function deletarPostagem() { 
        try {
            // Verificar se o usuário atual é o dono da postagem, no back é verificado tb
            if (postagem && postador.userUid == user.uid) {
                
                //Chamar deleta postagem
                try{
                    let result = await deletarPostagemGlobal(postagem.id);
                    if(result && result.success){
                        window.location.reload();
                    }else{
                        console.error('Erro ao deletar postagem:', result.message);
                        alert("Erro ao deletar postagem. Já estamos cientes e trabalhando nisso <3");
                    }
                }
                catch(error){
                  console.log(error);
                }
            } else {
                console.log("Você não tem permissão para deletar esta postagem ou a postagem não existe mais.");
                //N precisa tratar essa exibição, pois o frontend já n exibirá o botão de deletar
            }
        } catch (error) {
            console.log(error);
        }
    };

   

    async function handleUnfollow(uidPostador) {
        const result = await handleSeguirOuNao(user.uid, uidPostador);
        if (result) { //Deu certo
            // Atualize a lista de postagens
            const resultado = result.segue==false?0:1;
            setListaPostagens(prevList => 
                prevList.map(postagem => 
                    postagem.id === postagemSelecionadaDados.id
                    ? { ...postagem, seguindo: resultado } 
                    : postagem
                )
            );
        }
    
        setModalDeletePostagem(false);
    }

    function handleGenerateLink(url) {
        // Pega a URL base
        const baseUrl = window.location.origin;
    
        // Gera a nova URL com o parâmetro da postagem
        const newUrl = `${baseUrl}?p=${url}`;

        // Copia a URL gerada para a área de transferência
        navigator.clipboard.writeText(newUrl).then(() => {
            //Adicionar flutuante aq
            setModalDeletePostagem(false);
        }).catch(err => {
            console.error('Erro ao copiar o link: ', err);
        });
    
    }

    console.log(user);

    return (
        <>
        <div className={style.fadeBackground} onClick={()=>setModalDeletePostagem(false)}></div>
        <div className={style.container}>            
                {user && postagem && (
                    option === "inicio" && (
                    <ul className={style.ulOpcoes}>
                        {user.uid !== postador.userUid && //Postagem de terceiro
                            <>
                            {postagem.seguindo==true&&
                                <li className={style.redOption} onClick={()=>handleUnfollow(postador.userUid)}>
                                Deixar de seguir
                                </li>
                            }
                            
                            </>
                        }
                        {user.uid === postador.userUid && //Postagem própria
                            <li onClick={deletarPostagem}>Deletar postagem</li>
                        }
                        <li onClick={()=>handleGenerateLink(postagem.url)}>Copiar link</li>
                        <li onClick={()=>setModalDeletePostagem(false)}>Cancelar</li>

                    </ul>
                    )
                )}
            
        </div>
        </>
    );
}
