import React, { useState } from 'react';
import style from './style.module.css'; 
import ShowPostagensDenunciadas from './ShowPostagensDenunciadas';
import MenuEsquerdo from "../MenuEsquerdo";
import {fetchAndSetPostagens, obterToken} from '../../global';
import apiUrl from '../../global';
import { useEffect, useRef } from 'react';
import loadingGif from "../../Imgs/loadingIcon.gif"
import TodosComentarios from './ShowComentariosDenunciados';

function Home({user, setUser}) {

/********************************ABAIXO APENAS FUNCOES PARA POSTAGENS IMAGENS/VIDEOS *****************************************/
  const [offset, setOffset] = useState(0); //Offset pra load de postagens.
  const [limit, setLimit] = useState(10); //Limite de postagens loadadas por vez
  const [listaPostagens, setListaPostagens] = useState([]); //Guarda todas as postagens, assim como dados do postador.
  const [loadMore, setLoadMore] = useState(false); //Se true, é gatilho pra fazer load de postagem.
  const [loading, setLoading] = useState(true); //Se false quando já tiver fzd requisicao pra load de postagem, usado pra n fzer varias requisicoes
  const [atingiuFimPostagens, setAtingiuFimPostagens] = useState(false);//Fica true ao scrollar pro final da página
  const [errorLoadPost, setErrorLoadPost] = useState(""); //Exibe erro se não conseguir fazer load das postagens
  const [tipo, setTipo] = useState("postagens"); //Tipo postagens para exibir postagens, comentarios para exibir comentarios
  useEffect(()=>{ //Load de postagens iniciais
    setLoadMore(true); //N pode inicializar como true na hr de criar, tem q mudar pra true pra fazer load! Se n da lenha.
  },[])

  useEffect(()=>{ //Load de postagens
    async function pegarDados(){
      try{
        let postagensNovas;
        postagensNovas = await fetchAndSetPostagens('',"denuncia",offset,limit,user.uid);
        
        console.log(postagensNovas);
          setListaPostagens(prevPostagens => [...prevPostagens, ...postagensNovas]);
          if (postagensNovas.length < limit) {
            setAtingiuFimPostagens(true); // Define que atingiu o fim se menos postagens forem retornadas
          }
      } catch(error){
        setErrorLoadPost(`Ocorreu um erro ao carregar as postagens. Lamentamos pelo inconveniente e já estamos trabalhando para resolver o problema. Agradecemos muito sua compreensão.🙏`)
      }finally{
        setOffset(prevOffset => prevOffset + limit);
        setLoadMore(false);
      }
    }
    if(loadMore===true && user){
      pegarDados();
    }
  },[loadMore, user])

  const postagensRef = useRef(null);
  useEffect(() => { //Captar scroll
    const handleScroll = () => {
        if (postagensRef.current) {
          const { scrollTop, scrollHeight, clientHeight } = postagensRef.current;
          if(atingiuFimPostagens){
            console.log("Fimm");
          }else{
            if (scrollTop + clientHeight >= scrollHeight - 100 && loading) { 
              console.log("vai pokemon");
              setLoadMore(true); //Ativa listener pra pegar mais posts
              setLoading(false); //Impede de chamar dnv
              setTimeout(() => { //Dps de 3 secs ativa a possibilidade de load dnv
                setLoading(true);
              }, 1000);
            } 
          }
          
        }
    };

    const div = postagensRef.current;
    if (div) {
      div.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (div) {
        div.removeEventListener('scroll', handleScroll);
      }
    };
  },[loading, loadMore]); 

  const [scrollTop, setScrollTop] = useState(0); //Para guardar scroll
  const handleScroll = (event) => {
    setScrollTop(event.target.scrollTop); // Atualiza o estado com a posição do scroll
  };
  
  function exibirPostagens(){ //Funcao com return para exibir as postagens
    return(
      <>
      <p>Todas as postagens:</p>
        {user&&<ShowPostagensDenunciadas listaPostagens={listaPostagens} setListaPostagens={setListaPostagens} user={user}/>}

        {!loading&&<div className={style.divGift}><img src={loadingGif} className={style.gifLoading}/></div>}

        {atingiuFimPostagens && (
          //Visualizou todas as postagens
          offset >0&& <p className={style.fimPostagens}>Nada mais por aqui. Que tal seguir algumas páginas?</p>
        )}
        {errorLoadPost&&<p className={style.errorMessage}>{errorLoadPost}</p>}
      </>
    )
  }


  function ExibirModeradores(){
    const [modPromo, setModPromo] = useState({
      input: "",
      errorMessage:""
    });

    const [verifyProfile, setVerifyProfile] = useState({
      input: "",
      errorMessage:""
    });

    const [getUsername, setGetUsername] = useState({
      input: "",
      errorMessage: ""
    });

    async function onClickPromoverMod(tipo){
      //Tipo pode ser "promover" ou "remover"
      const token = await obterToken();
      const uid = modPromo.input; //Uid do usuario a ser promovido
      try {
        setModPromo(prevState => ({ //Limpando erro
          ...prevState,
          errorMessage: ""
        }))
        const response = await fetch(`${apiUrl}/promoverModerador`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` 
            },
            body: JSON.stringify({
              uid: uid,
              tipo: tipo
            }),
        });
      
            if (!response.ok) {
              const erro = await response.json();
              const mensagemErro = erro.message;
              setModPromo({
                input:"",
                errorMessage: `Erro ao tentar ${tipo} moderador: ${mensagemErro}`
              })
            }else{
              setModPromo({
                input:"",
                errorMessage: `Ação ${tipo} realizada com sucesso!`
              })
            }
        } catch (error) {
            console.error('Erro:', error);
        }
    }

    async function onClickVerifyProfile(tipo){
      //Tipo pode ser "promover" ou "remover"
      const token = await obterToken();
      const uid = verifyProfile.input; //Uid do usuario a ser promovido
      try {
        setVerifyProfile(prevState => ({ //Limpando erro
          ...prevState,
          errorMessage: ""
        }))
        const response = await fetch(`${apiUrl}/verificarPerfil`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` 
            },
            body: JSON.stringify({
              uid: uid,
              tipo: tipo
            }),
        });
      
            if (!response.ok) {
              const erro = await response.json();
              const mensagemErro = erro.message;
              setVerifyProfile({
                input:"",
                errorMessage: `Erro ao tentar ${tipo} verificacao de perfil: ${mensagemErro}`
              })
            }else{
              setVerifyProfile({
                input:"",
                errorMessage: `Ação ${tipo} verificacao de perfil realizada com sucesso!`
              })
            }
        } catch (error) {
            console.error('Erro:', error);
        }
    }

    async function obterUidFromUsername(){
      //Tipo pode ser "promover" ou "remover"
      const token = await obterToken();
      const uid = verifyProfile.input; //Uid do usuario a ser promovido
      try {
        setGetUsername(prevState => ({ //Limpando erro
          ...prevState,
          errorMessage: ""
        }))
        const response = await fetch(`${apiUrl}/getUidByUsername`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` 
            },
            body: JSON.stringify({
              username:getUsername.input
            }),
        });
      
            const resp = await response.json();
            const mensagem = resp.message;
            if (!response.ok) {
              setGetUsername({
                input:"",
                errorMessage: `Erro obter username: ${mensagem}`
              })
            }else{
              setGetUsername({
                input:"",
                errorMessage: `UID: ${mensagem}`
              })
            }
      
        } catch (error) {
            console.error('Erro:', error);
        }
    }

    return(
      /*
        Verificar/desverificar perfil, promover/remover mod, etc, tem q estarem em componentes separados ou pelo menos botões separados,
        pois pode acontecer de 2 ou mais moderadores tentar verificar e acabar "desverificando"
      */
    <div>
      <p>Ao promover ou remover promoção de alguém, o user é automaticamente deslogado para que seus claims sejam atualizados.</p>
      <fieldset className={style.promoverMod}>
        <p className={style.promoverModTitle}>Promover moderador (ADM APENAS)</p>
        <span className={style.promoverModSpan}>
          <input type="text" placeholder='Digite UID' value={modPromo.input} onChange={(e) => setModPromo(prevState => ({ ...prevState, input: e.target.value }))}/>
          <button onClick={()=>onClickPromoverMod("promover")}>Promover</button>
          <button onClick={()=>onClickPromoverMod("remover")}>Remover</button>
        </span>
        {modPromo.errorMessage}
      </fieldset>

      <fieldset className={style.promoverMod}>
        <p className={style.promoverModTitle}>Verificar perfil</p>
        <span className={style.promoverModSpan}>
          <input type="text" placeholder='Digite UID' value={verifyProfile.input} onChange={(e) => setVerifyProfile(prevState => ({ ...prevState, input: e.target.value }))}/>
          <button onClick={()=>onClickVerifyProfile("promover")}>Verificar</button>
          <button onClick={()=>onClickVerifyProfile("remover")}>Desverificar</button>
        </span>
        {verifyProfile.errorMessage}
      </fieldset>

      <fieldset className={style.promoverMod}>
        <p className={style.promoverModTitle}>Obter UID a partir de username</p>
        <span className={style.promoverModSpan}>
          <input type="text" placeholder='Digite username' value={getUsername.input} onChange={(e)=>setGetUsername(prevState => ({...prevState,input:e.target.value}))}/>
          <button onClick={obterUidFromUsername}>Obter</button>
        </span>
        {getUsername.errorMessage}
      </fieldset>


    </div>
    )
  }

  return (
    <React.Fragment>
      <div className={style.container} onScroll={handleScroll} ref={postagensRef}>
        <div className={style.espaco_esquerdo}>{<MenuEsquerdo user={user} setUser={setUser}/>}</div> {/*Grid espaco-esquerdo*/}
        <div className={style.espaco_central}> {/*Grid espaco-central*/}
          <div className={style.stories}></div>
          <div className={style.postagens} >
            <div className={style.groupButtonTop} >
              <button onClick={()=>setTipo("postagens")}>Postagens</button>
              <button onClick={()=>setTipo("comentarios")}>Comentários</button>
              <button onClick={()=>setTipo("moderadores")}>MOD</button>
            </div>
          {tipo=="postagens" ?
            exibirPostagens()
            :tipo=="comentarios"?
            <TodosComentarios/>
            :tipo=="moderadores"&&
            <ExibirModeradores/>
          }

          </div>
        </div>
      </div>
      </React.Fragment>
  );
}

export default Home;
