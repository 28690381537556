import React, { useState } from 'react';
import styles from './Login.module.css';
import { auth } from '../firebaseConfig';
import {setPersistence,browserLocalPersistence, signInWithEmailAndPassword,createUserWithEmailAndPassword } from 'firebase/auth'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import axios from 'axios';
import apiUrl from "../global";
import GoogleIco from "../Imgs/googleIco.png"

function loginWithEmail(email, senha, setExibirErro) {
  setPersistence(auth, browserLocalPersistence)
    .then(() => {
      // Persistência definida, agora podemos realizar o login
      return signInWithEmailAndPassword(auth, email, senha);
    })
    .then(async (userCredential) => {
      // Login bem-sucedido, userCredential contém informações do usuário
      //const user = userCredential.user; N é preciso pegar nada aq, o listener no App.js detecta login e pega os dados automaticamente
    })
    .catch((error) => {
      const errorCode = error.code;
      let errorMessage;
      switch (errorCode) {
        case 'auth/user-disabled':
          errorMessage = 'O usuário correspondente ao email fornecido foi desativado.';
          break;
        case 'auth/wrong-password':
          errorMessage = 'A senha fornecida está incorreta.';
          break;
        case 'auth/invalid-credential':
          errorMessage = 'Email ou senha incorreto';
          break;
        case 'auth/invalid-email':
          errorMessage = 'Email inválido';
          break;
        case 'auth/missing-password':
          errorMessage = 'Preencha a senha para entrar';
          break;
        default:
          errorMessage = 'Ocorreu um erro ao fazer login.';
          break;
      }
      setExibirErro(errorMessage);
    });
}

function removerAcentosESimbolos(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^\w\s]/gi, '').replace(/\s+/g, '');
}

async function gerarUsername(nome, sobrenome) {
  function removerAcentosESimbolos(str) { // Função para normalizar
    if (typeof str !== 'string') {
      throw new TypeError('O argumento deve ser uma string');
    }
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')  // Remove acentos
      .replace(/[^a-zA-Z0-9]/g, '')  // Remove caracteres especiais
      .replace(/\s+/g, '');  // Remove espaços
  }

  function gerarSufixoAleatorio(digitos) { // Função para gerar sufixo aleatório com base no número de dígitos
    return Math.random().toString(36).slice(2, 2 + digitos); // Gera um sufixo aleatório com o número especificado de dígitos
  }

  // Limpar o nome e sobrenome e torná-los minúsculos
  const cleanedName = removerAcentosESimbolos(nome).toLowerCase();

  const cleanedSobrenome = removerAcentosESimbolos(sobrenome).toLowerCase();
 
  let usernameBase = `${cleanedName}${cleanedSobrenome}`;
  let sufixoTamanho = 5; // Começa com um sufixo de 5 dígitos

  while (true) {
    const sufixo = gerarSufixoAleatorio(sufixoTamanho);
    const usernameToCheck = `${usernameBase}${sufixo}`;

    try {
      console.log("aq chega");
      // Verificar se o username já existe chamando a rota da API
      
      const response = await fetch(`${apiUrl}/verificaSeExisteUsername`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: usernameToCheck }),
      });
      const data = await response.json();

      if (data.exists === false) { //N tem esse username
        return usernameToCheck;  // Nome de usuário disponível, retornando
      }

      // Se o username já existir, aumentar o tamanho do sufixo adicional
      sufixoTamanho++;
    } catch (error) {
      console.error('Erro ao verificar username:', error);
      throw new Error('Erro ao verificar username');
    }
  }
}


async function registrarUsuario(registerData, setExibirErroRegister, setListenerWorking) {
  const { email, senha, nome, sobrenome, senhaRepeat, emailRepeat } = registerData;

  if (email !== emailRepeat) {
    setExibirErroRegister("Erro: A confirmação do email não corresponde ao email fornecido.");
    return;
  }

  if (senha !== senhaRepeat) {
    setExibirErroRegister("Erro: A confirmação da senha não corresponde à senha fornecida.");
    return;
  }

  try {
    setListenerWorking(false);

    // Gerar username
    const username = await gerarUsername(nome,sobrenome);

    // Criar usuário no Firebase Auth
    const userCredential = await createUserWithEmailAndPassword(auth, email, senha);

    if (userCredential) {
      const uid = userCredential.user.uid; // Obter o UID do usuário

      // Salvar dados adicionais no MySQL
      
      const criarUser = await axios.post(`${apiUrl}/criarUsuario`, {
        nome,
        sobrenome,
        email,
        username,
        uid,
        providerId:"hevelim",
        profile_image:""
      });

      if (criarUser.status === 201) {
        console.log("Logado com sucesso");
      }else if(criarUser.status ===200){
        console.log("Criado com sucesso")
      } else {
        await auth.currentUser.delete(); // Remove o usuário criado no Firebase Auth

        setExibirErroRegister("Erro ao criar usuário no MySQL. Erro de sql.");
        return;
      }
    }

  } catch (error) {
    if (error.code === "auth/email-already-in-use") {
      setExibirErroRegister("Erro: Este email já está em uso.");
    } else if (error.code === "auth/weak-password") {
      setExibirErroRegister("Erro: A senha fornecida é muito fraca.");
    } else if (error.code === "auth/invalid-email") {
      setExibirErroRegister("Erro: O email fornecido é inválido.");
    } else {
      console.error('Erro ao registrar usuário:', error);
      setExibirErroRegister("Erro ao registrar usuário. Tente novamente mais tarde.");
    }
    return;
  } finally {
    setListenerWorking(true);
  }
}

async function loginWithGoogle(setExibirErro, setListenerWorking) {
  function splitName(fullName) {
    const parts = fullName.split(' ');
    const firstName = parts.shift(); // Remove o primeiro nome
    const lastName = parts.join(' '); // Junta o restante como sobrenome
    return { firstName, lastName };
  }

  setListenerWorking(false);
  const provider = new GoogleAuthProvider();

  try {
    // Faz o login com o Google
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    if(!user.uid){
      console.log("Erro ao fazer login com o google");
      setExibirErro("Erro ao fazer login com o google.");
      return
    }

    // Dados do usuário
    const { displayName, email, uid, emailVerified, photoURL } = user; //Qd é logado com o google, n me interessa se o email é verificado ou não
    const providerId = result.providerId;
    const { firstName, lastName } = splitName(displayName || '');
    // Gerar username
     const username = await gerarUsername(firstName,lastName);
    // Salvar dados adicionais no MySQL
    
    const criarUser = await axios.post(`${apiUrl}/criarUsuario`, {
      nome: firstName, 
      sobrenome: lastName,
      email,
      username,
      uid,
      profile_image:photoURL,
      providerId
    });

    if (criarUser.status === 201) {
      console.log("Criado com sucesso");
    } else if(criarUser.status === 200) {
      console.log("Logado com sucesso");
    }else {
      await auth.currentUser.delete(); // Remove o usuário criado no Firebase Auth
      setExibirErro("Erro ao criar usuário no MySQL. Erro de sql.");
      return;
    }
  } catch (error) {
    setExibirErro('Ocorreu um erro ao fazer login com Google.');
  } finally {
    setListenerWorking(true);
  }
}

function Login({setListenerWorking}) {
  const [activeForm, setActiveForm] = useState('login');
  const [registerData, setRegisterData] = useState({email:"",senha:"",nome:"",sobrenome:"",emailRepeat:"",senhaRepeat:""});
  const [loginData,setLoginData] = useState({email:"",senha:""})
  const toggleForm = (formType) => {setActiveForm(formType);};
  const [exibirErro,setExibirErro] = useState("");
  const [exibirErroRegister,setExibirErroRegister] = useState("");
  const handlePaste = (e) => {e.preventDefault();};
  function showregisterform(){
    return(
      <form className={styles.form} onSubmit={(e) => { e.preventDefault(); registrarUsuario(registerData, setExibirErroRegister, setListenerWorking); }}>
        <div className={styles.formGroup}>
          <input type="text" placeholder="Nome" required value={registerData.nome} onChange={(e) => { setRegisterData({ ...registerData, nome: e.target.value }); }} />
        </div>
        <div className={styles.formGroup}>
          <input type="text" placeholder="Sobrenome" required value={registerData.sobrenome} onChange={(e) => { setRegisterData({ ...registerData, sobrenome: e.target.value }); }} />
        </div>
        <div className={styles.formGroup}>
          <input type="email" placeholder="Email" required value={registerData.email} onChange={(e) => { setRegisterData({ ...registerData, email: e.target.value }); }} />
        </div>
        <div className={styles.formGroup}>
          <input type="email" placeholder="Confirme seu email" onPaste={handlePaste} required value={registerData.emailRepeat} onChange={(e) => { setRegisterData({ ...registerData, emailRepeat: e.target.value }); }} />
        </div>
        <div className={styles.formGroup}>
          <input type="password" placeholder="Senha" required value={registerData.senha} onChange={(e) => { setRegisterData({ ...registerData, senha: e.target.value }); }} />
        </div>
        <div className={styles.formGroup}>
          <input type="password" placeholder="Confirme senha" onPaste={handlePaste} required value={registerData.senhaRepeat} onChange={(e) => { setRegisterData({ ...registerData, senhaRepeat: e.target.value }); }} />
        </div>
        <div className={styles.checkBoxDiv}>
          <label for="concordo">Ao se cadastrar, você concorda com nossa <span className={styles.link} onClick={() => window.open('/privacidade', '_blank')}>política de privacidade</span></label>
        </div>
        <button className={styles.entrar}>Register</button>
        <p className={styles.loginError}>{exibirErroRegister}</p>
      </form>
    );
  }

  function showloginform(){
    return(
      <span>
        <form className={styles.form} onSubmit={(e) => { e.preventDefault(); loginWithEmail(loginData.email, loginData.senha, setExibirErro); }}>
          <div className={styles.formGroup}>
            <input type="email" placeholder="Email" required autoComplete="email" value={loginData.email} onChange={(e) => { setLoginData({ ...loginData, email: e.target.value }); }} />
            <input type="password" placeholder="Senha" autoComplete="current-password" required value={loginData.senha} onChange={(e) => { setLoginData({ ...loginData, senha: e.target.value }); }} />
          </div>
          <button className={styles.entrar}>Entrar</button>
          <p className={styles.loginError}>{exibirErro}</p>
        </form>
        <span className={styles.flexGoogle}>
            <p> Ou </p> 
          <button className={styles.googleButton} onClick={() => loginWithGoogle(setExibirErro,setListenerWorking)}>
            <img src={GoogleIco} className={styles.googleIco}/>
            <p>Entrar com o google</p>
          </button> 
        </span>
      </span>
    );
  }
  return (
    <div className={styles.container}>
      <div className={styles.loginRegisterHeader}>
        <button className={activeForm === 'login' ? styles.active : styles.notactive} onClick={() => toggleForm('login')}>Login</button>
        <button className={activeForm === 'register' ? styles.active : styles.notactive} onClick={() => toggleForm('register')}>Register</button>
      </div>
      {activeForm === 'login' && showloginform()}

      {activeForm === 'register' && showregisterform()}
    </div>
  );
}

export default Login;






