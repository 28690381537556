import React, { useState, useRef, useEffect } from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import loading from "../../Imgs/LoadSnail.gif";
import trashIcon from '../../Imgs/trash.png';
import axios from 'axios';
import style from "./style.module.css";
import { obterToken } from '../../global';
import apiUrl from "../../global";
import imgpost from "../../Imgs/addimage100px.png"
import { FaArrowLeft } from 'react-icons/fa';
import ShowUserLittleProfile from '../ShowUserLittleProfile';
function ShowModalCriarPostagem({onClickFadeBackground,user}) {
  const [error, setError] = useState("");
  //const [descricaoPostagem, setDescricaoPostagem] = useState(""); //guarda texto da postagem
  const [isLoadingModal, setIsLoadingModal] = useState(false); //true? está enviando postagem, telinha de carregando
  const [postagem, setPostagem] = useState({ descricao: "", imagens: [], publico: "seguidores"}); //Resume tudo aq na hr de enviar pro back
  const [modalNumero, setModalNumero] = useState("modal1"); //Controla qual modal vai aparecer na tela
  const meufile = useRef();
  // const podePostarGlobal = verificaPodePostarGlobal();

  // function verificaPodePostarGlobal(){
  //   const creationTime = new Date(user.creationTime); // Converte para Date
  //   const currentTime = new Date(); // Data e hora atuais
  
  //   // Calculando a diferença em milissegundos e convertendo para dias
  //   const diffTime = Math.abs(currentTime - creationTime);
  //   const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  //   const limite =3; //PS: PAREADO COM O BACK
  //   if(diffDays<limite){
  //     return false;
  //   }else{
  //     return true;              
  //   }
  // }

  useEffect(()=>{ //Para passar para o modal2
    if(postagem.imagens.length>0){
      setModalNumero("modal2");
    }else{
      setModalNumero("modal1");
    }

    if(postagem.imagens.length>7){
      setError("Você só pode anexar 7 imagens por postagem.");
    }
  },[postagem])

  async function enviarPostagem() {
    if (Array.isArray(postagem.imagens) && postagem.imagens.length === 0) {
      setError("É necessário selecionar pelo menos uma imagem");
      return;
    }
    setIsLoadingModal(true);
    try {
      const idToken = await obterToken();
      const formData = new FormData();
      formData.append('descricao', postagem.descricao);
      formData.append('publico', postagem.publico);
      postagem.imagens.forEach((file) => {
        formData.append('imagens', file);
      });

      const response = await axios.post(`${apiUrl}/criarPostagem`, formData, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      setPostagem({ descricao: "", imagens: [], publico:"seguidores" });
      onClickFadeBackground();
      window.location.reload();
    } catch (error) {
      handlePostError(error);
    } finally {
      setIsLoadingModal(false);
    }
  }
  
  function handlePostError(error) {
    if (error.response && error.response.data) {
      const { message } = error.response.data;
      setError(message || 'Ocorreu um erro inesperado.');
    } else {
      setError('Ocorreu um erro inesperado.');
    }
  }
  function handleFileChange(event) {
    const selectedFiles = Array.from(event.target.files);
    const validFiles = selectedFiles.filter(file => file.type.startsWith('image/'));

    if (validFiles.length !== selectedFiles.length) {
      alert('Um dos arquivos que você selecionou não é uma imagem e foi ignorado.');
    }

    validateFiles(validFiles).then((filteredFiles) => {
      setPostagem(prevPostagem => ({
        ...prevPostagem, // Mantém o estado anterior
        imagens: [...prevPostagem.imagens, ...filteredFiles] // Atualiza apenas o campo "imagens"
      }));
    });
    
  }
  async function validateFiles(validFiles) {
    const validImageFiles = [];
    for (const file of validFiles) {
      try {
        await checkImageDimensions(file);
        validImageFiles.push(file);
      } catch {
        // File rejected, do nothing
      }
    }
    return validImageFiles;
  }
  function checkImageDimensions(file) {
    //ATENÇÃO: NÃO MEXER NA FUNCAO ABAIXO, ELA É PAREADA COM O BACKEND
    //SE FOR MEXER NELA, TEM Q MEXER NA DO BACK TB
    //CORRESPONDE AO verificaProporcaoImagem DO BACK
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          const ratio = width / height;
          const isSizeValid = img.width >= 300 && img.height >= 300; // Verifica se a largura e altura são pelo menos 500px

          // Definindo os formatos permitidos
          const formatosPermitidos = [
            { ratio: 16 / 9, ratioString: '16:9' },
            { ratio: 4 / 3, ratioString: '4:3' },
            { ratio: 3 / 2, ratioString: '3:2' },
            { ratio: 1 / 1, ratioString: '1:1' },
            { ratio: 2 / 3, ratioString: '2:3' },
            { ratio: 5 / 4, ratioString: '5:4' },
            { ratio: 2 / 1, ratioString: '2:1' }
          ];
  
          const tolerancia = 0.20; // ±20%, CUIDADO! TEM Q ESTAR PAREADO COM A PROPORCAO DO BACKEND
          // Verifica se o formato está dentro da tolerância permitida
          const formatoEncontrado = formatosPermitidos.find(({ ratio: formato }) => {
            return Math.abs(ratio - formato) <= tolerancia;
          });

          console.log("width:",width);
          console.log("height: ",height);
          console.log("ratio",formatoEncontrado);
  
          if (formatoEncontrado && isSizeValid) {
            resolve(file); // A imagem está dentro das proporções permitidas
          } else {
            alert(`A imagem ${file.name} está fora das proporções permitidas ou é menor que 400px e foi ignorada.`);
            reject();
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });
  }
  const onClickDeletePreviewImage = (index) => () => {
    setPostagem(prevPostagem => {
      const novasImagens = [...prevPostagem.imagens];
      novasImagens.splice(index, 1);
      if(novasImagens.length<8){
        setError(""); //Tira o erro caso tenha, erro de maximo 7 imgs
      }
      return { ...prevPostagem, imagens: novasImagens };
    });
  };

  /*Funções componentezais*/
  function segundoModal(){
    return(
      <div className={style.flexContainer}>

          <div className={style.flexSuperior}> {/*Parte superior*/}
            <div className={style.modalHeader}>
              <FaArrowLeft size={24} className={style.iconVoltar} onClick={()=>setModalNumero("modal1")}/>
              <p className={style.title}>Criar nova postagem</p>
              <span onClick={isLoadingModal ? null : enviarPostagem} className={style.iconSend}><FaPaperPlane /></span>
            </div>
            <hr className={style.divisaoHr}/>
          </div>

          <div className={style.flexInferiorModal2}>

            {isLoadingModal ? (
              <div className={style.loadingImg}>
                <p>Você pode continuar usando a plataforma enquanto é feito upload de sua postagem.</p>
                <img src={loading} alt="Loading" />
              </div>
            ) 
            : 
            (
              <div>
                {<ShowUserLittleProfile user={user} naoclicavel={true}/>}
                <textarea name="descricao" placeholder='Máximo 2000 caracteres' maxLength="2000" value={postagem.descricao} onChange={ (e)=>{setPostagem(prevState=>({...prevState, descricao: e.target.value}))} }></textarea>
                <div className={style.buttonContainer}>
                  <select className={style.escolherPublico} value={postagem.publico} onChange={ (e) => setPostagem(prevState => ({ ...prevState, publico: e.target.value })) }>
                    <option value="seguidores">Seguidores, amigos e perfil</option>
                    {/* {podePostarGlobal? <option value="global">Global</option>: <option value="global" disabled>Global - após 3 dias da criação da conta.</option>} */}
                    <option value="global">Global</option>
                  </select>
                </div>
                <p className={style.error}>{error}</p>

                <div className={style.selectedMidia}>
                  {postagem.imagens.map((file, index) => (
                    <div key={`imgsprevieww${index}`} className={style.divSelectedMidia}>
                      <img onClick={onClickDeletePreviewImage(index)} src={URL.createObjectURL(file)} alt={`Imagem ${index}`} className={style.imagePreview} />
                      <img src={trashIcon} className={style.trashIcon} />
                    </div>
                  ))}
                  {/*Botão adicionar mais fotos*/}
                  <div className={style.divSelectedMidia}>
                    <img src={imgpost} className={style.imagePreview} onClick={()=>meufile.current.click()}/>
                    <input type="file" multiple ref={meufile} style={{ display: 'none' }} accept="image/*" onChange={handleFileChange} />
                  </div>
                </div>

            </div>
            )}

          </div>
      </div>
    )
  }
  function primeiroModal(){
    return(
      <div className={style.flexContainer}> {/*Flex column*/}
      
        <div className={style.flexSuperior}> {/*Parte superior*/}
          <div className={style.modalHeader}>
            <FaArrowLeft size={24} className={style.iconVoltar} onClick={()=>onClickFadeBackground()}/>
            <p className={style.title}>Criar nova postagem</p>
            <span/>
          </div>
          <hr className={style.divisaoHr}/>
        </div>

        <div className={style.flexInferiorModal1}>{ /*Parte inferior, flex column center*/}
            <img src={imgpost} className={style.imgPost}/>
            <button onClick={() => meufile.current.click()} className={style.buttonSelectMidia}>Selecionar imagens</button>
            <p className={style.error}>{error}</p>
            <input type="file" multiple ref={meufile} style={{ display: 'none' }} accept="image/*" onChange={handleFileChange} />
        </div>
      </div>
    )
  }
   

  return (
    <div className={style.modalPostagem}>
      {modalNumero=="modal1"?
        primeiroModal()
        :
        modalNumero=="modal2"&&
        segundoModal() //N pode ser componente, problema de rerender por ter q alterar var fora dele
      }
      
      
    </div>
  );
}

export default ShowModalCriarPostagem;
