import style from "./index.module.css";
import { useState, useEffect, useRef,React } from "react";
import {FaRegHeart, FaHeart,FaPlay, FaVolumeMute, FaVolumeUp } from 'react-icons/fa';
import { calcularTempoDecorrido,obterToken } from "../../global";
import apiUrl from "../../global";
import DescricaoComMais from "../DescricaoComMais/DescricaoComMais";
import axios from 'axios'; 
import ModalDeletePostagemComp from "../ShowPostagens/ModalDeletePostagem"
import Carousel from "../ShowPostagens/Carousel";
import ModalDeleteComentario from "./ModalDeleteComentario";
import { AiOutlineLeft } from 'react-icons/ai';
import globo from "../../Imgs/globo2020.png";
import seguidores from "../../Imgs/seguidores.png";
//Comentar (criar comentario)
function InputComentar({replyButton, setListaPostagens,setReplyButton, scrollFull, setPostagemSelecionadaDados,postagemSelecionadaDados, setComentarios, idPostagem, userId,user, comentarios,setOcultarComentario }) {
  const [novoComentario, setNovoComentario] = useState(""); // Armazena o novo comentário
  const [isDisabled,setIsDisabled] = useState(false);
  async function handleComents() {
    if(isDisabled) return;
    if (novoComentario.trim() === "") return;
    setIsDisabled(true);
    const token = await obterToken();

    try {
      // Envia o novo comentário para o backend
      const idComentarioPai = replyButton.idComentarioPai?replyButton.idComentarioPai:"";
      const response = await axios.post(`${apiUrl}/criarcomentario`, 
      {
        comentario: novoComentario,
        postagemId: idPostagem,
        idComentarioPai:idComentarioPai
      },
      {
        headers: {
          Authorization: `Bearer ${token}` // Inclui o token no cabeçalho da requisição
        }
      }
      );
  
      if (response.data.success) {
        IncrementarComentsNaPostagem();

        // Se a adição for bem-sucedida, atualiza os comentários no frontend (força aparecer ele)
        const comentarioCompleto = {
          ...response.data.novoComentario,
          ...user //Como é o user q comentou, nem precisa buscar, basta colocar o user logado
        };

        if(idComentarioPai){
            setComentarios(prevComentarios => 
              prevComentarios.map(comentario => {
                if (comentario.id === idComentarioPai) {
                  return {
                    ...comentario,
                    replies: comentario.replies ? [comentarioCompleto, ...comentario.replies] : [comentarioCompleto]
                  };
                } else {
                  return comentario; // Garantir que todos os outros comentários sejam mantidos
                }
              })
            );
        }else{
          setComentarios(prevComentarios => [
            comentarioCompleto, // Adiciona o novo comentário no início
            ...prevComentarios
          ]);
        }

         // Atualiza o array de IDs para não buscar o comentário novamente (afinal agt forçou ele no useState)
         setOcultarComentario(prevOcultarComentario => [
          response.data.novoComentario.id, // Adiciona o ID do novo comentário
          ...prevOcultarComentario
        ]);
        
        //Leva o scroll pro topo:
        if (scrollFull.current) {
          scrollFull.current.scrollTo({
            top: 0,
            behavior: 'smooth', // Faz o scroll suave
          });
        }
        
        //Caso não seja via URL, mudamos tb na lista de postagens, pra quando fechar o modal estiver com a msm qtd comentarios
        if(setListaPostagens){ 
          setListaPostagens(prevLista => 
            prevLista.map(postagem => 
              postagem.id === idPostagem
                ? {
                    ...postagem,
                    quantidadeDeComentarios: postagem.quantidadeDeComentarios + 1,
                  }
                : postagem
            )
          );
        }

        // Limpa o campo de input
        setNovoComentario("");
        //Tira o target de reply de comentarios, caso tenha
        setReplyButton({
          idComentarioPai: "",
          ImageDonoComentario: "",
          usernameDonoComentario: ""
        });
      } else {
        console.error('Erro ao adicionar comentário:', response.data.message);
      }
    } catch (error) {
      console.error('Erro ao adicionar comentário:', error);
    }finally{
      setIsDisabled(false);
    }
  }
  function IncrementarComentsNaPostagem() {
    // Atualiza a quantidade de comentários
    setPostagemSelecionadaDados(prevState => ({
        ...prevState, // Preserva o restante das propriedades de item
        quantidadeDeComentarios: prevState.quantidadeDeComentarios + 1
    }));
  };

  return (
    <div className={style.parteFixa}> {/*Flex column start*/}
      {replyButton.idComentarioPai&&
        <div className={style.imageReplyContainer} onClick={()=>setReplyButton("")}>
          <img className={style.imageReply} src={replyButton.ImageDonoComentario} />
          <span className={style.closeIcon}>X</span>
        </div>
      }
      
      <input 
        className={style.parteFixaInput} 
        type="text" 
        placeholder={"Responder "+replyButton.usernameDonoComentario || "Adicione um comentário"} 
        value={novoComentario} 
        onChange={(e) => setNovoComentario(e.target.value)} 
        disabled={isDisabled}
      />
      <button className={style.parteFixaButton} onClick={handleComents}>Enviar</button>
    </div>
  );
}

//Comentários
function TodosComentarios({setReplyButton, idPostagem, userId, comentarios, setComentarios, ocultarComentario, setModalDeleteComenthandler}) { 
    const [error, setError] = useState(null);
    const [loadingComments, setLoadingComments] = useState(true); //Armazena se está em load ou não os comentários
    const [limitLoad, setLimitLoad] = useState(10); //Quantos comentários carregam por vez
    const [offsetLoad, setOffsetLoad] = useState(0); //Vai carregar os comentários a partir desse indice
    const [exibirShowMore, setExibirShowMore] = useState(false); //N exiba o showMore de início
    let isRunningHandleLike = false;
    function ShowMore(){
      function onClickShowMore(){
        setOffsetLoad(offsetLoad+10);
      }
      return(
        <p>
        {exibirShowMore ? (
          <span onClick={onClickShowMore} className={style.exibirMais}>Exibir mais</span>
        ) : (
        <span>&nbsp;</span> //Espaço q o Exibir mais ocupa, é bom ter pra melhorar experiência do usuário
        )}
      </p>
      )
    }
    useEffect(() => { // Pegar os comentários e dados do usuário q comentou
      setLoadingComments(true);
      const fetchComentarios = async () => {
        try {
            const token = await obterToken();

            const response = await fetch(`${apiUrl}/comentarios`, {
                method: 'POST', // Mude para POST
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    idPostagem: idPostagem,
                    limitLoad: limitLoad,
                    offsetLoad: offsetLoad,
                    comentariosOcultos: ocultarComentario
                })
            });
    
            const comentarios = await response.json(); // Aguarde a resposta JSON
            const numeroDeComentarios = comentarios.length; // Quantidade de comentários retornados
            setExibirShowMore(numeroDeComentarios === limitLoad); // Se chegou realmente os 10 comentários, pode ser que tenha mais
            
            if (offsetLoad === 0) {
                setComentarios(comentarios); // Primeiros 10 comentários
            } else {
                setComentarios(prevComentarios => [...prevComentarios, ...comentarios]); // Outros entram em congregação
            }
            console.log(comentarios);
            setLoadingComments(false);
        } catch (err) {
            setError('Erro ao buscar comentários');
            setLoadingComments(false);
        }
      };
    
  
      fetchComentarios();
    },[offsetLoad]);

    const handleLike = async (comentario) => {
      if(isRunningHandleLike) return;
      isRunningHandleLike = true;

      let comentarioId = comentario.id;
      const acao = comentario.curtido?"descurtir":"curtir";
      
     

      try {
        const token = await obterToken();
        
        const response = await fetch(`${apiUrl}/curtirComentario`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Incluindo o token no cabeçalho
          },
          body: JSON.stringify({ comentarioId, acao})
        });

        if (!response.ok) {
          throw new Error('Erro ao curtir comentário');
        }
        const data = await response.json();
        if (data.success) {
          if (comentario.idComentarioPai) {
            // é um reply/filho
            setComentarios(prevComentarios =>
              prevComentarios.map(c => {
                if (c.id === comentario.idComentarioPai) { //Achei o pai dele
                  return {
                    ...c,
                    replies: c.replies.map(reply =>
                      reply.id === comentario.id //Achei o filho q qro incrementar/decrementar
                        ? { 
                            ...reply, 
                            curtido: !reply.curtido, 
                            qtdCurtidas: reply.qtdCurtidas + (!reply.curtido ? 1 : -1) 
                          }
                        : reply
                    )
                  };
                } else {
                  return c;
                }
              })
            );
          } else {
            setComentarios(prevComentarios =>
              prevComentarios.map(c =>
                c.id === comentario.id
                  ? { 
                      ...c, 
                      curtido: !c.curtido, 
                      qtdCurtidas: c.qtdCurtidas + (!c.curtido ? 1 : -1) 
                    }
                  : c
              )
            );
          }
        }
        
 
      } catch (error) {
        console.error('Erro ao curtir comentário:', error);
      }finally{
        isRunningHandleLike=false;
      }
    };
    function onClickDeleteComent(comentario){
      setModalDeleteComenthandler(prevState=>({
        ...prevState,
        ativo: true,
        comentario:comentario,
        setComentarios: setComentarios
      }))
    }

    function handleClickResponder(id, profileImage, username){
      setReplyButton({
        idComentarioPai:id,
        ImageDonoComentario:profileImage,
        usernameDonoComentario: username
      })
    }

    async function onClickExpandeRespostas(comentario){
      const comentarioId = comentario.id;
      let countReply = comentario.countReply;
      let limit = 5;
      let offset = comentario.offsetReply? comentario.offsetReply : 0;
      try{
        const token = await obterToken();
        const response = await fetch(`${apiUrl}/expandirComentario`, {
            method: 'POST', // Mude para POST
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                comentarioId: comentarioId,
                limit: limit,
                offset: offset,
                idPostagem: idPostagem,
            })
        });
        if(response.ok){
          const resposta = await response.json();
          const count = resposta.length; 
          setComentarios(prevComentarios => 
            prevComentarios.map(item => 
                item.id === comentarioId 
                    ? {
                        ...item, 
                        countReply: item.countReply - count,
                        offsetReply: (item.offsetReply || 0) + count,
                        replies: [...(item.replies || []), ...resposta]  // Concatenando as novas respostas com as já existentes
                    }
                    : item
            )
          );

        }
       
      }catch(error){
        console.log(error);
      }
    }

    function ExibirComentario({comentario, respAvailable}){
      return(
        <div className={style.comentarioUpper}> {/*Flex row*/}
            <img className={style.imgComents} src={comentario.profile_image} alt="Avatar" />
            <div className={style.nomeEComentario}>
                <p className={style.nomeComentarista}>{comentario.nome} {comentario.sobrenome} </p>
                <p className={style.tresPontos} onClick={()=>onClickDeleteComent(comentario)}>...</p>
                <p className={style.usernameComentarista}>@{comentario.username}</p>
                <span>{comentario.comentario}</span>
                <div className={style.atributosComentario}>
                  <span 
                    onClick={() => handleLike(comentario)} 
                    className={style.botaoCurtir}
                  >
                    {comentario.curtido ? <FaHeart color="red" /> : <FaRegHeart />}
                  </span>
                  <p>{comentario.qtdCurtidas} amei</p>
                  {
                    respAvailable==true &&
                    <p className={style.cursorPointer} onClick={()=>handleClickResponder(comentario.id, comentario.profile_image, comentario.username)}>Responder</p>
                  }
                  <p>{calcularTempoDecorrido(comentario.dataComentario)}</p>
                </div>  
                {comentario.replies && comentario.replies.map((reply) => (
                    <div key={reply.id} className={style.replyShow}>
                        <ExibirComentario comentario={reply} respAvailable={false}/>
                    </div>
                ))}
                {
                  comentario.countReply>0 && respAvailable===true &&
                  <div className={style.replyContainer} onClick={()=>onClickExpandeRespostas(comentario)}> 
                    <span className={style.replySpan}><hr/></span>
                    <p>Exibir respostas ({comentario.countReply})</p>
                  </div>
                }
            </div>
        </div>
      )
    }
    
    return (<>
        <div className={style.comentario}>
        {comentarios && comentarios.length!=0 && comentarios.map((comentario, index) => (
          <ExibirComentario comentario={comentario} key={comentario.id} respAvailable={true}/>
        ))}
      </div>{<ShowMore/>}
    </>);
}

function Video({postagemSelecionadaDados, mutado}){
  const [isPlaying, setIsPlaying] = useState(true); // Estado para controlar se o vídeo está tocando
  const [isMuted, setIsMuted] = useState(mutado==true?true:false); // Estado para controlar o áudio
  var videoSrc = postagemSelecionadaDados.video.caminhoImagem;
  const videoRefPUnica = useRef(null);
  const [progress, setProgress] = useState(0); //Controla progresso do video

 //Se o modal for aberto por url, precisa iniciar mutado, senao nao inicia o video automaticamente

  const toggleMute = () => {
    if (videoRefPUnica.current) {
        const newMuteState = !isMuted;
        videoRefPUnica.current.muted = newMuteState; 
        setIsMuted(newMuteState); 
    }
  };

  const togglePlay = () => {
    if (videoRefPUnica.current) {
      if (isPlaying) {
        videoRefPUnica.current.pause();
      } else {
        videoRefPUnica.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleProgressChange = (e) => {
    const video = videoRefPUnica.current;
    const newTime = (e.target.value / 100) * video.duration;
    video.currentTime = newTime;
    setProgress(e.target.value);
  };
  
  const handleTimeUpdate = () => {  // Atualiza o progresso da barra enquanto o vídeo está tocando
    const video = videoRefPUnica.current;
    const currentProgress = (video.currentTime / video.duration) * 100;
    setProgress(currentProgress);
  };
 return(
  <div className={style.divVideo}>
    <video
      ref={videoRefPUnica}
      onClick={togglePlay}
      className={style.video}
      src={videoSrc}
      muted={isMuted}
      loop
      autoPlay
      onPlay={()=>setIsPlaying(true)}
      onPause={()=>setIsPlaying(false)}
      onTimeUpdate={handleTimeUpdate}
    />
    <div onClick={togglePlay} className={style.playButton}>
      {!isPlaying &&<FaPlay className={style.iconePlay}/>}
    </div>

      {/* Barra de progresso */}
      {!isPlaying &&
      <div className={style.progressBarContainer}>
          <input
            type="range"
            className={style.progressBar}
            value={progress}
            onChange={handleProgressChange} // Permite ao usuário arrastar a barra
            min="0"
            max="100"
          />
          <div className={style.progressFilled} style={{ width: `${progress}%`}}/>
      </div>
      }

    <div onClick={toggleMute} className={style.muteButton}>
      {isMuted ? <FaVolumeMute className={style.iconeMute} /> : <FaVolumeUp className={style.iconeMute} />}
    </div>
  </div>
 )
}

/*Descricao da postagem, qts curtidas da postagem, qts comentarios, etc, parte superior */
function ParteSuperior({setModalDeletePostagem, postagemSelecionadaDados, userId, setPostagemSelecionadaDados, setListaPostagens, setShowModalPostagemUnicaHandler}) {
  const postagem = postagemSelecionadaDados;
  const postador = postagemSelecionadaDados.postador;
  const postagemId = postagemSelecionadaDados.id;
  let isRunningHandleLike = false;

  async function handleLike(){
    if(isRunningHandleLike) return;
    isRunningHandleLike=true;
    const token = await obterToken();
    try {
      const acao = postagem.curtido?"descurtir":"curtir";

      const response = await axios.post(`${apiUrl}/curtirPostagem`, 
        { postagemId, acao }, // Corpo da requisição
        { 
            headers: {
            'Authorization': `Bearer ${token}`, // Inclui o token no cabeçalho
            'Content-Type': 'application/json' // Garante que o corpo da requisição está no formato JSON
            }
        }
      );

      const success = response.data.success;
      if(success){
          //Mudando "curtido" e qtd de curtida na postagem
          setPostagemSelecionadaDados(prevState => ({
              ...prevState,
              quantidadeDeCurtidas: prevState.quantidadeDeCurtidas + (!prevState.curtido==true?1:-1),
              curtido: !prevState.curtido 
          }));

          //Caso não seja via URL, mudamos tb na lista de postagens, pra quando fechar o modal estiver curtido tb
          if(setListaPostagens){ 
            setListaPostagens(prevLista => 
              prevLista.map(postagem => 
                postagem.id === postagemId
                  ? {
                      ...postagem,
                      quantidadeDeCurtidas: postagem.quantidadeDeCurtidas + (!postagem.curtido==true?1:-1),
                      curtido: !postagem.curtido 
                    }
                  : postagem
              )
            );
          }
      }

    } catch (error) {
      console.error('Erro ao curtir/descurtir postagem:', error);
    }finally{
      isRunningHandleLike = false;
    }

  }
  return (
    <span>
          <span className={style.mobileUpperComments} onClick={()=>setShowModalPostagemUnicaHandler(false)}>
            <AiOutlineLeft size={24} />
            <p>Comentários</p>
            <span></span> {/*Não tirar esse span, serve pra justify content*/}
          </span>
          <div className={style.container}>
            <div className={style.comentarioUpper}> {/*Flex row, 20 gap, separa img do nome/username*/}
              <img className={style.imgPost} src={postador.profile_image?postador.profile_image:"https://firebasestorage.googleapis.com/v0/b/socialcity-702b9.appspot.com/o/universal%2Fprofile.jpeg?alt=media&token=cfcad71a-e3a6-43b9-bf0a-95c6e9c23f5c"} alt="Avatar" />
              <div className={style.nomeEDescricaoPost}> {/*Flex column*/}
                  <div className={style.nomeUserN}>
                    <span className={style.nomeEGlobo}>
                      <p className={style.nomeComentarista}>{postador.nome} {postador.sobrenome}</p>
                      {
                        postagemSelecionadaDados.publico=="global"?
                        <img src={globo} title="Postagem global"/>:
                        <img src={seguidores} title="Postado no perfil/seguidores"/>
                      }
                    </span>
                    <p className={style.usernameComentarista}>@{postador.username}</p>
                    <p className={style.tresPontos} onClick={() => setModalDeletePostagem(true)}>...</p>
                  </div>
                  <p>{<DescricaoComMais descricao={postagem.descricao} limite={100}/>}</p>
                  <div className={style.atributosComentario}>
                    <span className={style.ameiButton} onClick={handleLike}>{postagem.curtido?<FaHeart color="red"/>:<FaRegHeart/>}</span>
                    <p>{postagem.quantidadeDeCurtidas} amei</p>
                    <p>2d</p>
                    <p>{postagem.quantidadeDeComentarios ? postagem.quantidadeDeComentarios : "0"} comentários</p>
                  </div>
              </div>
            </div>
            <hr className={style.separarComentarios} />
            <span className={style.spanComentDisp}>
              <p>Comentários disponíveis</p>
            </span>
          </div>
    </span>

  );
}

export default function ModalPostagemUnica({showModalPostagemUnicaHandler, setPostagemSelecionadaDados, postagemSelecionadaDados, setShowModalPostagemUnicaHandler,setListaPostagens,user, midia, mutado }) {
 console.log(postagemSelecionadaDados);
  const userId = user.uid;
  const [showFadeBackground, setShowFadeBackground] = useState(false);
  const [modalDeletePostagem,setModalDeletePostagem] = useState(false);
  /*Variáveis para comentários*/
  const [comentarios, setComentarios] = useState([]); //Armazena os comentários
  const [ocultarComentario, setOcultarComentario] = useState([]); //Comentarios adicionados não podem serem pegos novamente na query, pois já estão em exibição
  const [janelaW, setjanelaW] = useState(window.innerWidth);
  const scrollFull = useRef(null);
  const [replyButton, setReplyButton] = useState({ //Se tem algo é pq é pra reply de coment
    idComentarioPai: "",
    ImageDonoComentario: "",
    usernameDonoComentario: ""
  }); 
  

       

  useEffect(() => { //Ao voltar no celular, fecha o modal em vez de ir pra página anterior
    window.history.pushState(null, '', window.location.href);// Adiciona uma entrada ao histórico quando o modal é aberto
    /************Volta a url para o normal, favor n mexer senao buga************/ //Mudar url é demorado demais, se fizermos isso pouco antes de fechar o modal, o modal fecha antes de mudar de url completamente, oq faz o modal abrir dnv, msm aparentemente mudando a url
    const currentUrl = new URL(window.location.href);
    currentUrl.search = ''; // Remove a query string (tudo depois de "?")
    currentUrl.hash = '';   // Remove o hash (tudo depois de "#")
    window.history.replaceState(null, '', currentUrl.href); // Substitui a URL atual com a versão sem parâmetros

    
    const handlePopState = () => {
      setShowModalPostagemUnicaHandler(false);
    };

    window.addEventListener('popstate', handlePopState);

    // Limpeza do listener ao desmontar o modal
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [showModalPostagemUnicaHandler]); //Handler como dependencia (pois é ao abrir e ao fechar modal)


  window.addEventListener('resize', () => {
    let larguraJanela = window.innerWidth;
    setjanelaW(larguraJanela);
  });
 

  const [modalDeleteComentHandler, setModalDeleteComenthandler] = useState({
    ativo: false,     //Controla a exibição do modal
    userId:user.uid,  //Usuario logado, pra comparar se é o dono do comentario
    comentario: "",   //Comentario q qr deletar (pra pegar id & usuario q postou)
    setComentarios:setComentarios, //lista de todos os comentarios pra atualizar a lista qd excluir o comentario.
    setPostagem: setPostagemSelecionadaDados //Para atualizar a quantidadeDeComentarios da postagem
  });

  function removeAllQueryParamsFromCurrentUrl() {
    const url = new URL(window.location.href);
    url.search = ''; // Remove todos os parâmetros de consulta
    window.history.replaceState({}, '', url.toString());
  }
 
  function onClickCloseModal() {
    removeAllQueryParamsFromCurrentUrl();
    setShowFadeBackground(false);
    setShowModalPostagemUnicaHandler(false);
  }
  
  return (
    <>
    {modalDeleteComentHandler.ativo&&<ModalDeleteComentario idPostagem={postagemSelecionadaDados.id} handler={modalDeleteComentHandler} setHandler={setModalDeleteComenthandler} setListaPostagens={setListaPostagens}/>}
    {modalDeletePostagem && <div className={style.modalDelete}><ModalDeletePostagemComp postagemSelecionadaDados={postagemSelecionadaDados} user={user} setModalDeletePostagem={setModalDeletePostagem} setListaPostagens={setListaPostagens}/></div>}
    {userId&&
      <div className={style.modalContainer}>
        <div className={style.fadeBackground} onClick={() => onClickCloseModal()}></div>
        {postagemSelecionadaDados && (
          <div className={style.modalContent}> {/*Flex row*/}
          {midia &&
              <span className={style.midias}>
                {/*Sessão parte esquerda onde exibe a postagem em sí */
                  postagemSelecionadaDados.imagens?.length>0?
                    <div className={style.limitaCarousel}>
                      <Carousel postagem={postagemSelecionadaDados}/>
                    </div>

                  :postagemSelecionadaDados.video?.caminhoImagem?.length>0&&
                  <div className={style.limitaCarousel}>
                    <Video postagemSelecionadaDados={postagemSelecionadaDados} mutado={mutado}/>
                  </div>
                }
              </span>
          }

            {/*Sessão parte direita onde exibe os comentários*/}
            <div className={style.sessaoComentario}> 
              <div className={style.scrollFull} ref={scrollFull}>
                <ParteSuperior setModalDeletePostagem={setModalDeletePostagem} postagemSelecionadaDados={postagemSelecionadaDados} userId={userId} setPostagemSelecionadaDados={setPostagemSelecionadaDados} setListaPostagens={setListaPostagens} setShowModalPostagemUnicaHandler={setShowModalPostagemUnicaHandler}/>
                
                <div className={style.comentarios}>
                  <TodosComentarios setReplyButton={setReplyButton} idPostagem={postagemSelecionadaDados.id} userId={userId} comentarios={comentarios} setComentarios={setComentarios} ocultarComentario={ocultarComentario} setModalDeleteComenthandler={setModalDeleteComenthandler}/>
                </div>
              </div>
              <InputComentar setListaPostagens={setListaPostagens} replyButton={replyButton} setReplyButton={setReplyButton} scrollFull={scrollFull} setPostagemSelecionadaDados={setPostagemSelecionadaDados} postagemSelecionadaDados={postagemSelecionadaDados} setComentarios={setComentarios} idPostagem={postagemSelecionadaDados.id} userId={userId} user={user} comentarios={comentarios} setOcultarComentario={setOcultarComentario}/>
            </div>
          </div>
        )}
      </div>
    }
    </>
  );
}
