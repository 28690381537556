import { useState } from "react";
import style from "./style.module.css";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
export default function Carousel({ postagem}) {
    const [carouselIndex, setCarouselIndex] = useState(0);

    const onClickCarousel = (direction) => () => {
        const maxIndex = postagem.imagens.length - 1;
        const newIndex = carouselIndex + (direction === 'right' ? 1 : -1);
        if (newIndex >= 0 && newIndex <= maxIndex) {
            setCarouselIndex(newIndex);
        }
    };

    const imagemAtual = postagem.imagens && postagem.imagens[carouselIndex];
    const urlImagem = imagemAtual ? imagemAtual.caminhoImagem.trim() : '';

    return (
            <div className={style.containerImgsPost}>
                {urlImagem && (
                    <img
                        src={urlImagem}
                        className={style.postagemCorpoImg}
                        alt={`Postagem de ${postagem.postador.username_postador}`}
                    />
                )}

                <div className={style.controladoresCarousel}> {/*Fica em cima da img pra colocar os controladores*/}
                    {postagem.imagens && postagem.imagens.length > 0 && carouselIndex > 0 && (
                        <div onClick={onClickCarousel("left")} className={style.arrowCarousel}>
                            <FaArrowCircleLeft className={style.iconeSeta}>Anterior</FaArrowCircleLeft>
                        </div>
                    )}
                    <div className={style.divOpenModalPostagem}></div>
                    {postagem.imagens && postagem.imagens.length > 0 && carouselIndex < postagem.imagens.length - 1 && (
                        <div onClick={onClickCarousel("right")} className={style.arrowCarousel}>
                            <FaArrowCircleRight className={style.iconeSeta}>Avançar</FaArrowCircleRight>
                        </div>
                    )}
                </div>
            </div>
    );
}