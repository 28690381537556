import React, { useState, useEffect, useRef  } from 'react';
import style from './index.module.css';
import { AiOutlineClockCircle, AiOutlineComment } from 'react-icons/ai';
import { RiSendPlaneLine } from 'react-icons/ri';
import {FaHeart, FaRegHeart } from 'react-icons/fa';
import DescricaoComMais from '../../DescricaoComMais/DescricaoComMais';
import ModalDeletePostagemComp from './ModalDeletePostagem';
import ShowModalPostagemUnica from '../../ModalPostagemUnica';
import {capitalizeFirstLetter, calcularTempoDecorrido, obterToken} from '../../../global';
import apiUrl from "../../../global";

import axios from 'axios';
import ShowLittleProfileModal from "../../ShowLittleProfileModal";
import { useNavigate } from 'react-router-dom';
import Carousel from "./Carousel";
import ShowVideoShorts from './ShowVideoShorts';
import { IoMdClose } from 'react-icons/io';

function ConfirmaModal({punicao, setConfirmaModalHandler, postagemId, setListaPostagens, listaPostagens}){

    async function confirmarAcao(){
        console.log("Confirmando");
        console.log(punicao);
        try {
            const token = await obterToken();
            
            const response = await fetch(`${apiUrl}/julgarPostagem`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    postagemId,
                    punicao
                }),
            });

            console.log("Vamos tirar a prova");
            console.log(response);

            if(response.ok){
                console.log("Funcionou, agora vou remover da lista");
                const novaListaPostagens = listaPostagens.filter(postagem => postagem.id !== postagemId);
                setListaPostagens(novaListaPostagens);
                setConfirmaModalHandler(false);

            }else{
                const errorData = await response.json(); 
                console.error('Erro na resposta:', response.status, response.statusText);
            }

            const result = await response.json(); 
            console.log('Resultado JSON:', result);
        
        } catch (error) {
            console.error('Erro ao criar denuncia:', error);
        }
    }

    return(
        <>
        <div onClick={()=>setConfirmaModalHandler(false)} className={style.fadeBackgroundModal}></div>
        <div className={style.parteUsada}>
            <div className={style.titulo}><span></span><p>Confirmar ação</p> <IoMdClose className={style.icon} size={24} onClick={()=>setConfirmaModalHandler(false)}/> </div>
            <div>
                
                {
                punicao=="liberado"?
                     <>
                        <p>LIBERAR POSTAGEM</p>
                        <button className={style.liberar} onClick={()=>confirmarAcao()}>LIBERAR</button>
                     </>
                    :punicao=="warning"?
                    <>
                        <p>ADICIONAR WARNING</p>
                        <button className={style.warning} onClick={()=>confirmarAcao()}>WARNING</button>
                    </>:punicao=="excluirpost"?
                    <>
                        <p>EXCLUIR POSTAGEM</p>
                        <button className={style.excluirPostagem} onClick={()=>confirmarAcao()}>EXCLUIR</button>
                    </>:punicao=="baniruser"&&
                    <>
                        <p>BANIR USUÁRIO</p>
                        <button className={style.banirUsuario} onClick={()=>confirmarAcao()}>BANIR</button>
                    </>

                }
                
            </div>
        </div>
        </>
    )
}


// Componente PostagemHeader
function PostagemHeader({ postagem, handlerModalDeletePostagem }) {
    const [onHoverName, setOnHoverName] = useState(false);
    const navigate = useNavigate();
    const handleNavigate = (username) => {
        navigate(`/${username}`);
    };
    const postador = postagem.postador;
    return (
        <div>
            <div className={style.postagemHeader}>
                <img className={style.postagemHeaderImg} src={postador.profile_image} alt="User" />
                <div className={style.nameAndUsername} onMouseEnter={()=>setOnHoverName(true)}>
                    <span className={style.modalSpan} onMouseLeave={()=>setOnHoverName(false)}>
                        {onHoverName &&
                            <div className={style.modalStyle}>
                                <ShowLittleProfileModal postagem={postagem}/>
                            </div>
                        }
                        <p className={style.postagemName} onClick={()=>handleNavigate(postador.username)}>{capitalizeFirstLetter(postador.nome)} {capitalizeFirstLetter(postador.sobrenome)}</p>
                    </span>
                    <p className={style.postagemUserName}>@{postador.username}</p>
                </div>
                <span className={style.postagemTimeIcon}><AiOutlineClockCircle /></span>
                <p className={style.postagemHorario}>{calcularTempoDecorrido(postagem.criadoEm)}</p>
                <p className={style.deletarPostagem} onClick={() => handlerModalDeletePostagem(postagem)}>...</p>
            </div>
            <p className={style.tipoDenuncia}>{postagem.nomeDenuncia}</p>
        </div>
    );
}
// Componente PostagemInteracoes
function PostagemInteracoes({postagem,userId,setListaPostagens,setShowModalPostagemUnicaHandler,setPostagemSelecionadaDados}) {
    const postagemId = postagem.id;
    async function handleLike(){
        try {
            
          const response = await axios.post(`${apiUrl}/curtirPostagem`, { postagemId, userId });
          const novaQuantidadeCurtidas = response.data.quantidadeCurtidas; //Nova qtd de curtidas
          const userCurtiuOuDescurtiu = response.data.curtido; //recebe true ou false
           // Atualiza a lista de postagens da página home
          setListaPostagens(prevLista => 
            prevLista.map(posts => 
                posts.id === postagemId
                ? {
                    ...posts,
                    quantidadeDeCurtidas: novaQuantidadeCurtidas,
                    curtido: userCurtiuOuDescurtiu
                  }
                : posts
            )
          );
    
    
        } catch (error) {
          console.error('Erro ao curtir/descurtir postagem:', error);
        }
    
      }
    function onClickComments(){
        setPostagemSelecionadaDados(postagem);
        setShowModalPostagemUnicaHandler(true);
    }
    return (
        <span>
        <div className={style.interacoes}>
            <span onClick={handleLike} className={style.cursorPointer}>
                 {postagem.curtido?<FaHeart className="icon" color="red" />:<FaRegHeart className="icon" />}
            </span>
            <span onClick={onClickComments} className={style.cursorPointer}><AiOutlineComment className="icon" /></span>
            <span><RiSendPlaneLine className="icon" /></span>
            </div>
            <div className={style.descricaoPostagem}>
                {postagem.descricao&&
                <span>
                    <p>{postagem.postador.username}:</p>
                    <DescricaoComMais descricao={postagem.descricao} limite="200"/>
                </span>
                }
            </div>
        <hr className={style.hrQuebraPostagem}/>
        </span>
    );
}
// Componente PostagemCorpo
function PostagemCorpo({ postagem, globalMuteVideo,setGlobalMuteVideo,globalPauseVideo,setListaPostagens,listaPostagens }) {
    const videoRefs = useRef([]);
    const [confirmaModalHandler, setConfirmaModalHandler] = useState(false);
    const [punicaoSelecionada, setPunicaoSelecionada] = useState();
    useEffect(() => { //Listener intersection para dar play no video automaticamente
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            const videoElement = entry.target;
            if (entry.isIntersecting) {
                videoElement.preload = "auto";
                videoElement.play();
            } else {
                videoElement.preload = "metadata";
                videoElement.pause();
            }
          });
        }, { threshold: 0.6 }); //Porcentagem q tem q ta visivel na viewport pra executar o comendo (no caso, play).
    
        videoRefs.current.forEach(video => observer.observe(video));
    
        return () => {
          videoRefs.current.forEach(video => observer.unobserve(video));
        };
      }, [globalMuteVideo]);

    function handlePunimento(punimento){
        setPunicaoSelecionada(punimento);
        setConfirmaModalHandler(true);
    }
    return (
        <>
            {confirmaModalHandler&& <ConfirmaModal listaPostagens={listaPostagens} punicao={punicaoSelecionada} setConfirmaModalHandler={setConfirmaModalHandler} postagemId={postagem.id} setListaPostagens={setListaPostagens}/>}
            {postagem.imagens.length>0? 
            <div className={style.limitaCarousel}>
                <Carousel postagem={postagem}/>
            </div> : postagem.video && 
            <ShowVideoShorts postagem={postagem} videoRefs={videoRefs} globalMuteVideo={globalMuteVideo} setGlobalMuteVideo={setGlobalMuteVideo} globalPauseVideo={globalPauseVideo}/> 
            }
            <div className={style.opcoesModerador}>
                <button className={style.liberar} onClick={()=>handlePunimento("liberado")}>Liberar</button>
                <button className={style.warning} onClick={()=>handlePunimento("warning")} disabled={postagem.publico === "seguidores"}>
                {postagem.publico === "seguidores"?"**Já está fora do público**":"Warning (Tirar do publico + dac)"}
                </button>
                <button className={style.excluirPostagem} onClick={()=>handlePunimento("excluirpost")}>Excluir postagem</button>
                <button className={style.banirUsuario} onClick={()=>handlePunimento("baniruser")}>Banir usuário</button>
            </div>
        </>
    );
}
// Componente Principal ShowPostagens
export default function ShowPostagens({ listaPostagens,setListaPostagens, user}) { 

    /*
    postagemSelecionadaDados ou postagem(dentro de map) -> postagem específica
    listaPostagens -> todas as postagens
    */
    const [modalDeletePostagem, setModalDeletePostagem] = useState(false);//Controla abertura modal deletar postagem
    const [postagemSelecionadaDados, setPostagemSelecionadaDados] = useState([]); //A postagem q selecionar (no click) pra abrir no modal postagem única
    const [ShowModalPostagemUnicaHandler, setShowModalPostagemUnicaHandler] = useState(false);
    const [globalMuteVideo,setGlobalMuteVideo] = useState(true); //Muta todos os videos na timeline
    const [globalPauseVideo, setGlobalPauseVideo] = useState(false); //Pausa todos os videos na timeline, usado pra qd abrir modal

    useEffect(()=>{
        //Mutar videos ao abrir postagem unica modal.
        if(ShowModalPostagemUnicaHandler){
            setGlobalPauseVideo(true);
        }else{
            setGlobalPauseVideo(false);
        }
    },[ShowModalPostagemUnicaHandler])
    
    function handlerModalDeletePostagem(postagemSelecionada) {
        setPostagemSelecionadaDados(postagemSelecionada)
        setModalDeletePostagem(true);
    }

    return (
        <div className={style.todasPostagens}>

            {ShowModalPostagemUnicaHandler && user && (<ShowModalPostagemUnica setPostagemSelecionadaDados={setPostagemSelecionadaDados} postagemSelecionadaDados={postagemSelecionadaDados} setShowModalPostagemUnicaHandler={setShowModalPostagemUnicaHandler} user={user}setListaPostagens={setListaPostagens} midia={true}/>)}
            {modalDeletePostagem && <ModalDeletePostagemComp postagemSelecionadaDados={postagemSelecionadaDados} setListaPostagens={setListaPostagens} user={user} setModalDeletePostagem={setModalDeletePostagem}/>}

            {listaPostagens.map((postagem, index) => (
                <div className={style.postagem} key={`postagem-${index}`}>
                    <PostagemHeader postagem={postagem} handlerModalDeletePostagem={handlerModalDeletePostagem}/>
                    <PostagemCorpo listaPostagens={listaPostagens} postagem={postagem} globalMuteVideo={globalMuteVideo}setGlobalMuteVideo={setGlobalMuteVideo}globalPauseVideo={globalPauseVideo} setListaPostagens={setListaPostagens}/>
                    <PostagemInteracoes postagem={postagem} userId={user.uid} setListaPostagens={setListaPostagens} setShowModalPostagemUnicaHandler={setShowModalPostagemUnicaHandler} setPostagemSelecionadaDados={setPostagemSelecionadaDados}/>
                </div>
            ))}
        </div>
    );
}


