import { useState } from "react";
import style from "./DescricaoComMais.module.css";

export default function DescricaoComMais({ descricao, limite }) {
  const [mostrarCompleto, setMostrarCompleto] = useState(false);

  const toggleMostrarCompleto = () => {
    setMostrarCompleto(!mostrarCompleto);
  };

  if (descricao == null) descricao = "";

  const mostrarDescricao = mostrarCompleto ? descricao : `${descricao.slice(0, limite)}`;
  const adicionarReticencias = descricao.length > limite;

  return (
    <span className={style.paragrafoMostrarMais}>
      {mostrarDescricao}
      {adicionarReticencias && !mostrarCompleto && '...'}
      {descricao.length > limite && (
        <button onClick={toggleMostrarCompleto} className={style.botaoMostrarMais}>
          {mostrarCompleto ? 'menos' : 'mais'}
        </button>
      )}
    </span>
  );
};
