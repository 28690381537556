import style from "./style.module.css";
import logo from "../Imgs/logo100x90.png"
export default function LoadingScreen(){
    return(
        <div className={style.container}>
            <span></span>
            <img src={logo} alt="Logo" className={style.middleItem}/>
            <p className={style.bottomItem}>from VIORA</p>
        </div>
    )
}