import style from "./style.module.css";
import { useState, useEffect } from "react";
import {obterToken, handleLogout} from "../../global";
import { FaArrowLeft } from 'react-icons/fa';
import apiUrl from "../../global";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";


function Message({ content }) {
    return <p>{content}</p>;
}

function PayPalButton({user}) {
    const userId = user.uid;

    const initialOptions = {
        "client-id": "Ae-9x_i-jk4Yxmk74BINYoV_yRmeYP5VH2Rhd64a0WfTqevWp6ygaJAltowK0Y9IOHlvCiR7wZmKFpRh",
        "enable-funding": "venmo",
        "disable-funding": "",
        "buyer-country": "BR",
        currency: "BRL",
        "data-page-type": "product-details",
        components: "buttons",
        "data-sdk-integration-source": "developer-studio",
        locale: "pt_BR" // Definindo o idioma para português do Brasil
    };

    const [message, setMessage] = useState("");

    return (
        <div className="App">
            <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                    style={{
                        shape: "rect",
                        layout: "vertical",
                        color: "gold",
                        label: "paypal",
                    }} 
                    createOrder={async () => {
                        const token = await obterToken();
                        try {
                            const response = await fetch(`${apiUrl}/api/orders`, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    'Authorization': `Bearer ${token}`
                                },
                                // use the "body" param to optionally pass additional order information
                                // like product ids and quantities
                                body: JSON.stringify({
                                    userId: userId
                                    // purchase_units: [
                                    //     {
                                    //         reference_id: "verificarPerfil",
                                    //         custom: userId, // Aqui passamos o userId
                                    //         amount: {
                                    //             currency_code: "BRL",
                                    //             value: "50.00"
                                    //         }
                                    //     }
                                    // ]
                                }),
                            });

                            const orderData = await response.json();

                            if (orderData.id) {
                                return orderData.id; //Retornando pro onApprove 
                            } else {
                                const errorDetail = orderData?.details?.[0];
                                const errorMessage = errorDetail
                                    ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                                    : JSON.stringify(orderData);

                                throw new Error(errorMessage);
                            }
                        } catch (error) {
                            console.error(error);
                            setMessage(
                                `Não foi possível iniciar o Checkout do PayPal...${error}`
                            );
                        }
                    }} 
                    onApprove={async (data, actions) => {
                        try {
                            const response = await fetch(
                                `${apiUrl}/api/orders/${data.orderID}/capture`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                }
                            );

                            const orderData = await response.json();
                            // Three cases to handle:
                            //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                            //   (2) Other non-recoverable errors -> Show a failure message
                            //   (3) Successful transaction -> Show confirmation or thank you message

                            const errorDetail = orderData?.details?.[0];

                            if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                                // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                                return actions.restart();
                            } else if (errorDetail) {
                                // (2) Other non-recoverable errors -> Show a failure message
                                throw new Error(
                                    `Erro! ${errorDetail.description} (${orderData.debug_id})`
                                );
                            } else {
                                // (3) Successful transaction -> Show confirmation or thank you message
                                // Or go to another URL:  actions.redirect('thank_you.html');
                                const transaction =
                                    orderData.purchase_units[0].payments
                                        .captures[0];
                                // setMessage(
                                //     `Transação ${transaction.status}: ${transaction.id}. See console for all available details`
                                // );
                                 setMessage(
                                     `Muito obrigado por apoiar a plataforma! Transação realizada com sucesso. Aguarde alguns segundos e atualize a página ou feche e abra o app para conferir a mudança.`
                                 );
                                // console.log(
                                //     "Capture result",
                                //     orderData,
                                //     JSON.stringify(orderData, null, 2)
                                // );
                            }
                        } catch (error) {
                            console.error(error);
                            setMessage(
                                `Desculpe, sua transação não pode ser processada.. ${error}`
                            );
                        }
                    }} 
                />
            </PayPalScriptProvider>
            <Message content={message} />
        </div>
    );
}



function FormBasicInfo({user}){
    // Calcula a data máxima permitida para Date. Vc n pode colocar q nasceu a menos de 10 anos atrás, nem a mais de 120 anos.
    //Tome cuidado ao modificar esse cálculo, pois no backend é verificado se usuário tem menos de 10 ou mais de 120 tb.
    const today = new Date();
    const minDate = new Date(today.getFullYear() - 120, today.getMonth(), today.getDate()); // Permite datas a partir de 120 anos atrás
    const maxDate = new Date(today.getFullYear() - 10, today.getMonth(), today.getDate()); // Permite datas até 10 anos atrás
    const minDateStr = minDate.toISOString().split('T')[0];
    const maxDateStr = maxDate.toISOString().split('T')[0];

    const [erroMsg,setErroMsg] = useState("");
    const [basicUserInfo,setBasicUserInfo] = useState({
        nome:user.nome,
        sobrenome:user.sobrenome,
        sexo: user.sexo?user.sexo:"",
        dataNascimento: user.dataNascimento ? formatDateToInput(user.dataNascimento) : "",
    })
    const [blockForm,setBlockForm] = useState(false);
    function formatDateToInput(date) {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

    function handleInfoChange(event) {
        const { name, value } = event.target;

        if (name === 'nome') {
            // Remove caracteres não permitidos
            const sanitizedValue = value.replace(/[^a-zA-ZÀ-ÿ]/g, '');
            setBasicUserInfo(prevState => ({
                ...prevState,
                [name]: sanitizedValue
            }));
        }else if (name === 'sobrenome') {
            const sanitizedValue = value.replace(/[^a-zA-ZÀ-ÿ\s]/g, ''); //Sobrenome permite espaço
            setBasicUserInfo(prevState => ({
                ...prevState,
                [name]: sanitizedValue
            }));
        }
        else {
            // Para outros campos, usa o valor como está
            setBasicUserInfo(prevState => ({
                ...prevState,
                [name]: value
            }));
        }

    }

    async function handleSubmitBasicInfo(e){
        e.preventDefault();
        setBlockForm(true);
        try { 

            if (basicUserInfo.nome.length > 15) { ////pareado com input
                setErroMsg('O nome não pode ter mais de 15 caracteres');
                setBlockForm(false);
                return;
            }
            if (basicUserInfo.sobrenome.length > 15) { ////pareado com input
                setErroMsg('O sobrenome não pode ter mais de 15 caracteres');
                setBlockForm(false);
                return;
            }

            const token = await obterToken();
            
            const response = await fetch(`${apiUrl}/changeUserBasicInfo`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({ basicUserInfo })
            });
        
            if (!response.ok) {
              const errorData = await response.json();
              throw new Error(errorData.message || 'Erro desconhecido');
            }
        
            const data = await response.json();
            if(data){
                window.location.reload();
            }else{
                setErroMsg("Erro ao atualizar dados. Já estamos cientes e trabalhando nisso.");
            }
           
        } catch (error) {
            setErroMsg(`${error.message}`);
        }
        setBlockForm(false);

        
    }

    return(
        <span>
        <form className={style.section} onSubmit={handleSubmitBasicInfo}>
            <label>Nome</label>
            <input type="text" maxLength="15" title="Somente letras são permitidas" required placeholder="Nome" value={basicUserInfo.nome} name="nome" onChange={handleInfoChange} disabled={blockForm} />
            <label>Último sobrenome</label>
            <input type="text" maxLength="15" title="Somente letras e espaço são permitidos" required placeholder="Sobrenome" value={basicUserInfo.sobrenome} name="sobrenome" onChange={handleInfoChange} disabled={blockForm}/>
            <label htmlFor="sexo">Sexo</label>
            <select id="sexo" className={style.selectBox} name="sexo" onChange={handleInfoChange} value={basicUserInfo.sexo} disabled={blockForm}>
                <option value="" disabled>Selecione o sexo</option>
                <option value="M">Masculino</option>
                <option value="F">Feminino</option>
                <option value="O">Prefiro não responder</option>
            </select>
            <label>Data de Nascimento</label> 
            <input type="date" name="dataNascimento" onChange={handleInfoChange} value={basicUserInfo.dataNascimento} min={minDateStr} max={maxDateStr} disabled={blockForm}/>
            <button className={style.updateBtn}>Atualizar</button>
        </form>
        <p className={style.errMessageRed}>{erroMsg}</p>
        </span>
    )
}

function FormUserName({user}){
    const [newUsername, setNewUsername] = useState(user.username);
    const [msgError, setMsgError] = useState("Username pode conter apenas letras sem acento, números e underline.");
    const [blockedForm, setBlockedForm] = useState(false);
    async function onSubmitForm(e){
        e.preventDefault();
        // Verifica se o nome de usuário tem dois underscores consecutivos, começa ou termina com um underscore
        const usernamePattern = /^_|__$|__|_$/;

        if (usernamePattern.test(newUsername)) {
            setMsgError("Não é permitido começar nem terminar o nome com underscore, nem ter dois underscore seguidos.");
            return;
        }
        if(newUsername==user.username){
            setMsgError("Este já é seu username ;-;");
            return;
        }

        if(newUsername.length <3 || newUsername.length>20){ //pareado com input
            setMsgError("Username precisa ter entre 3 e 20 caracteres.");
            return;
        }
        
            
        setBlockedForm(true);
        setMsgError("");
        try { 
            const token = await obterToken();
            
            const response = await fetch(`${apiUrl}/changeUsername`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ newUsername })
            });
        
            const data = await response.json();

            if (!response.ok || !data) {
                setMsgError(data.message);
            }else{
                window.location.replace(`/${data.username}`);
            }
            
        } catch (error) {
            setMsgError(error.message);
        }finally {
            setBlockedForm(false); 
        }

    }
    function handleUsernameChange(e){
        const sanitizedUsername = e.target.value.replace(/[^a-zA-Z0-9_]/g, ''); //a-z A-Z 0-9 e a excessao: underline!
        setNewUsername(sanitizedUsername);
    }
    return(
        <form className={style.section} onSubmit={onSubmitForm}>
            <label>Username</label>
            <input type="text" maxLength={20} placeholder="Username" required value={newUsername} onChange={(e)=>handleUsernameChange(e)} disabled={blockedForm}/>
            <p>{msgError}</p>
            <button className={style.updateBtn}>Atualizar</button>
        </form>
    )
}

function FormDeletarConta({ user }) {
    /*
        Atenção: se for mudar o 120 segundos, mude tb no back, pois tem que estar pareados
    */
    const [googleOrEmail, setGoogleOrEmail] = useState(user.providerId=="google.com" ? "google" : "email");
    const [error, setError] = useState(""); //Variavel de erro q exibe pro cliente
    const [codigoDigitado, setCodigoDigitado] = useState(""); //Codigo q chegou no email digitado pelo cliente
    const [botaoEnviar, setBotaoEnviar] = useState({ //Botao enviar email
        texto: "Aguarde..",
        ativado: false,
        countDown: parseInt(localStorage.getItem('countdownDeletarConta')) || 0
    });

    const [password, setPassword] = useState("");

    useEffect(() => {
        if (botaoEnviar.countDown > 0) { //Se tiver countDown ao iniciar a página, entra aq
            const countdown = setInterval(() => {
                setBotaoEnviar((prevState) => {
                    const newCountDown = prevState.countDown - 1;
                    localStorage.setItem('countdownDeletarConta', newCountDown);
                    if (newCountDown <= 0) {
                        clearInterval(countdown);
                        localStorage.removeItem('countdownDeletarConta');
                        return { //Daí se acabar o countdown entra aq
                            texto: "Enviar código",
                            ativado: true,
                            countDown: 0
                        };
                    }
                    return { //Enquanto percorre o countdown entra aq
                        ...prevState,
                        ativado:false,
                        texto: `Reenviar em ${newCountDown} segundos`,
                        countDown: newCountDown
                    };
                });
            }, 1000);

            return () => clearInterval(countdown);
        }else{ //Mas se entrar na página e não tiver countdown, em vez de começar ativado, ele começa com aguarde e ativa só depois, para que comece sempre desativado.
                //Isso evita q comece ativado por alguns ms e desativa em seguida, criando uma má experiência de usuário.
            setBotaoEnviar((prevState)=>({
                ...prevState,
                ativado:true,
                texto:"Enviar código",
            }))
        }
    }, [botaoEnviar.countDown]);

    async function handleFormSubmit(e) { //Codigo para enviar codigo de validacao da conta (caso usuario registrado via google)
        e.preventDefault();
        if(botaoEnviar.countDown>0){ 
            return
        }
        setBotaoEnviar(prevState => ({
            ...prevState,
            ativado: false,
            texto: "Enviando..."
        }));

        const token = await obterToken();
        try {
            const response = await fetch(`${apiUrl}/sendEmailDeleteAccount`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                setError(errorData.message || 'Erro ao enviar email.');
            }if(response.status==429){ //too many requisicoes, isto é, de alguma forma enviou dnv antes de 2m
                setBotaoEnviar((prevState)=>({
                    ...prevState,
                    countDown:120
                }))
            } else {
                console.log("E-mail enviado com sucesso");
                // Inicia a contagem de 120 segundos
                setBotaoEnviar((prevState) => ({
                    ...prevState,
                    countDown: 120
                }));
            }
        } catch (error) {
            setError('Erro ao enviar código. Tente novamente mais tarde.');
        }
    }
    async function onClickDeletarConta(){ //Código para deletar conta
        if(codigoDigitado.trim()==""){
            setError("Digite o código para continuar");
            return;
        }

        const token = await obterToken();
        try {
            const response = await fetch(`${apiUrl}/deleteAccount`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ codigoDigitado, password })
            });

            if (!response.ok) {
                const errorData = await response.json();
                setError(errorData.message || 'Erro ao deletar conta.');
            }else{
                handleLogout();
            }
            
        } catch (error) {
            setError('Erro ao enviar código. Tente novamente mais tarde.');
        }  

        
        

    }
    return(
        <>
        <form className={style.section} onSubmit={handleFormSubmit}>
            <div className={style.textContainer}>
                <p>Sentiremos muito sua falta 😔</p>
                <p className={style.textoDeletarConta}>
                Ao clicar para excluir, sua conta será permanentemente apagada após 20 dias. Durante esse período, ninguém conseguirá ver seu perfil, postagens, fotos ou vídeos, preservando assim sua identidade. Quando sua conta for finalmente excluída, todos os dados, como fotos e vídeos que estavam ocultos, serão deletados permanentemente.

                Se durante esses 20 dias você mudar de ideia, basta entrar na sua conta novamente com suas credenciais para cancelar a exclusão.

                Esse tempo é necessário para evitar abusos, como o uso indevido da plataforma, e para garantir que o sistema não seja sobrecarregado, já que a exclusão de contas requer um processamento significativo.

                Caso alguém cometa um ato ilícito na plataforma, de acordo com as leis brasileiras, nós analisaremos as denúncias antes de proceder com a exclusão da conta. Tomaremos as devidas medidas, como bloquear permanentemente o e-mail e o número de celular do usuário para novos registros, além de usar suas informações para contatar as autoridades competentes.

                Se você está considerando sair porque algo não te agradou, saiba que estamos sempre trabalhando para melhorar a plataforma, adicionando novos recursos e removendo postagens que não estão em conformidade com nossos princípios. Que tal, em vez de excluir sua conta, nos enviar um feedback?

                Por fim, se sua decisão for definitiva, lembre-se de que, embora uma rede social possa ajudar, não é ela que te mantém no caminho certo com Deus, mas sim a Sua Palavra e a comunhão com pessoas que valorizam o que é bom e puro. Que Deus te abençoe grandemente.
                </p>
            </div>
            <label>Digite o motivo (opcional)</label>
            <input type="text" placeholder="Estou saindo porque..."/>
            <fieldset className={style.fieldSetDeletarConta}>
                <legend>Confirme que é você</legend>
                <select value={googleOrEmail} disabled onChange={(e)=>setGoogleOrEmail(e.target.value)}>
                    <option value="email">Eu tenho um email</option>
                    <option value="google">Eu faço login com o google</option>
                </select>
                {   <>
                        <p>Um código será enviado para sua conta {user.email}.</p>
                        <button type="submit" className={style.googleButton} disabled={!botaoEnviar.ativado}>{botaoEnviar.texto}</button> {/*TIpo button n da submit */}
                        <input value={codigoDigitado} onChange={(e)=>setCodigoDigitado(e.target.value)} type="text" placeholder="Digite o código que você recebeu" />
                    </>
                }
            </fieldset>
            <p>{error}</p>
            <button className={style.deletarButton} onClick={onClickDeletarConta}>Deletar conta</button>
        </form>
        </>
)
}

function FormVerificar({ user }) {
    const [msgError, setMsgError] = useState("");
    
    return (
        <div>
            <div className={style.section}>
                <p className={style.title}>Verifique seu perfil para:</p>
                <div className={style.containerVerificarPerfil}>
                    <p> Ganhar o selo de verificado</p>
                    <p> Melhorar a credibilidade do seu perfil</p>
                    <p> Aumentar o alcance das suas postagens</p>
                    <p> Aumentar o limite do tamanho dos vídeos de 75mb para 125mb</p>
                    <p> Aumentar a duração dos vídeos de 120 segundos para até 3 minutos</p>
                    <p> Contribuir para o crescimento da plataforma e, dessa forma, contribuir para espalharmos o evangelho. Nossa meta é que a plataforma saia do Brasil para o mundo!</p>
                </div>
                <PayPalButton user={user}/>
                <p>{msgError}</p>
            </div>
        </div>
    );
}

export default function ModalEditarPerfil({user,setModalEditarPerfilHandler}) {
    const [activeSection, setActiveSection] = useState('basic');
    console.log(user);
    return (
        <div className={style.container}>
            <div className={style.fadeBackground} onClick={()=>setModalEditarPerfilHandler(false)}></div>
            <div className={style.modal}>
                 
                <div className={style.menuLateral}>
                    <div className={style.containerVoltar} onClick={()=>setModalEditarPerfilHandler(false)}><FaArrowLeft size={24} /><p>Voltar</p></div>
                    <div className={style.botoes}>
                        <button 
                            className={style.menuBtn} 
                            onClick={() => setActiveSection('basic')}
                        >
                            Básico
                        </button>
                        <button 
                            className={style.menuBtn} 
                            onClick={() => setActiveSection('username')}
                        >
                            Username
                        </button>
                        {
                            user.claims=="admin"&&
                            <button 
                                className={style.menuBtn} 
                                onClick={() => setActiveSection('verificar')}
                            >
                                Verificar perfil
                            </button>
                        }
                        <button 
                            className={style.menuBtn} 
                            onClick={() => setActiveSection('deletar')}
                        >
                            Deletar conta
                        </button>
                    </div>
                </div>
                <div className={style.conteudo}>
                    {activeSection === 'basic' && (
                        <FormBasicInfo user={user}/>
                    )}
                    {activeSection === 'username' && (
                        <FormUserName user={user}/>
                    )}
                    {activeSection === 'deletar' && (
                        <FormDeletarConta user={user}/>
                    )}
                    {activeSection === 'verificar' && (
                        <FormVerificar user={user}/>
                    )}
                </div>
            </div>
        </div>
    );
}
