import style from "./index.module.css";
import { capitalizeFirstLetter } from "../../global";
import { useNavigate } from 'react-router-dom';
import verified from "../../Imgs/verified.png";
export default function Index({postagem,limiteCaracteres}) {
    const { nome, sobrenome, username, profile_image, postCount, seguidorCount, seguindoCount,pverificado } = postagem.postador;
    
    const navigate = useNavigate();
    // Função para lidar com a navegação
    const handleNavigate = () => {
        navigate(`/${username}`);
    };

    const nomeSobrenome = capitalizeFirstLetter(nome) +" "+ capitalizeFirstLetter(sobrenome);
    return (
        <div className={style.container}>
            <div className={style.containerUpper}>
                <img className={style.userImage} onClick={handleNavigate} src={profile_image} alt="User" />
                <div className={style.nameAndusername} onClick={handleNavigate}>
                    <span className={style.flexRow}>
                        <p className={style.name}>
                        {
                        limiteCaracteres && nomeSobrenome.length > limiteCaracteres 
                            ? nomeSobrenome.slice(0, limiteCaracteres) + "..." 
                            : nomeSobrenome
                        }
                        </p>
                        {pverificado==true?<img src={verified} title="Perfil verificado"/>:null}
                    </span>
                    {pverificado==true && <p className={style.pf}>Perfil verificado</p>}
                    <p className={style.userName}>
                        @{limiteCaracteres && username.length > limiteCaracteres 
                        ? username.slice(0, limiteCaracteres) + "..." 
                        : username}
                    </p>
                </div>
            </div>
            
            <div className={style.contaimerMiddle}>
                <div className={style.atributesContainer}>
                    <span>{postCount}</span>
                    <span>postagens</span>
                </div>
                <div className={style.atributesContainer}>
                    <span>{seguidorCount}</span>
                    <span>seguidores</span>
                </div>
                <div className={style.atributesContainer}>
                    <span>{seguindoCount}</span>
                    <span>seguindo</span>
                </div>
            </div>

        </div>
    );
}
