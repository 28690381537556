import style from "./ModalDeletePostagem.module.css";
import {deletarPostagemGlobal} from "../../global";
import { useEffect } from "react";
import { obterToken } from "../../global";
import { useState } from "react";
import { MdClose } from 'react-icons/md'; 
import { handleSeguirOuNao } from "../../global";
import apiUrl from "../../global";

import { useParams } from "react-router-dom";
import { MdArrowBack } from 'react-icons/md';




function DenunciarModal({setOption, setModalDeletePostagem, postagemSelecionadaDados}){
    const [tiposDenuncia, setTiposDenuncia] = useState([]);
    const [stagio, setStagio] = useState("stagio1"); 
    const [erro,setErro] = useState('');
    /*
    stagio 1 - Selecionar opção de denuncia(spam, nudez etc)
    stagio 2 - Confirmação de denuncia
    stagio 3 - Denunciado com sucesso
    stagio 4 - Você já denunciou essa postagem
    stagio 5 - Erro ao denunciar
    */
    const [opcaoSelecionada, setOpcaoSelecionada] = useState(); //Id opcao selecionada
    useEffect(()=>{
        async function getTipoDenuncia(){
            const token = await obterToken();
            try { 
                const token = await obterToken();
                
                const response = await fetch(`${apiUrl}/getTipoDenuncia`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                  },
                });
            
                if (!response.ok) {
                  const errorData = await response.json();
                  throw new Error(errorData.message || 'Erro desconhecido');
                }
            
                const data = await response.json();
                setTiposDenuncia(data);
                
              } catch (error) {
                throw new Error(error.message || 'Erro desconhecido');
              }
        }
        getTipoDenuncia();
    },[])

    function handleSelecionarOpcao(opcao){
        setOpcaoSelecionada(opcao); //Id opcao selecionada
        setStagio("stagio2"); 
    }

    async function handleDenunciar(){
        const idPostagem = postagemSelecionadaDados.id;
        const opcao = opcaoSelecionada;
        try {
            const token = await obterToken();
            
            const response = await fetch(`${apiUrl}/denunciarPostagem`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    idPostagem,
                    opcao
                }),
            });

            const result = await response.json();
            if(result.tipo=="vcjadenunciou"){
                setStagio("stagio4");
            }else if(result.tipo=="sucessodenuncia"){ //Denunciado com sucesso
                setStagio("stagio3");
            }else if(result.tipo="erro"){
                setStagio("stagio5");
                setErro(result.message);
            }
         
        
        } catch (error) {
            console.error('Erro ao criar denuncia:', error);
            setStagio("stagio5"); //Exibe erro
        }
    }



    return (
            <div className={style.containerDenuncia}>
                {stagio=="stagio1"?
                    <div>
                        <div className={style.reasonContainer}>
                        <MdArrowBack className={style.icon} size={24} onClick={()=>setOption("inicio")}/>
                        <p className={style.title}>Selecione o motivo</p>
                        <MdClose size={24} className={style.icon} onClick={()=>{setModalDeletePostagem(false)}}/>
                        </div>

                        <ul className={style.ulDenuncia}>
                            {tiposDenuncia && tiposDenuncia.data && tiposDenuncia.data.length > 0 ? (
                            tiposDenuncia.data.map((tipo, index) => (
                                <li key={index} onClick={()=>handleSelecionarOpcao(tipo.id)}> {tipo.nome}</li>
                            ))
                            ) : (
                            <li>Erro ao exibir tipos de denuncia</li> // Exibe uma mensagem de fallback
                            )}
                        </ul>

                    </div>
                : stagio=="stagio2"? //Confirmacao de denuncia
                    <div>
                        <p className={style.titulo}>Você tem certeza que quer denunciar?</p>
                        <p className={style.corpoDenuncia}>
                        Sua denúncia será cuidadosamente revisada pelos nossos moderadores. 
                        Dependendo da gravidade da situação, a postagem pode ser removida ou o usuário responsável pode enfrentar medidas como a perda da conta.
                        Agradecemos sinceramente por ajudar a manter nossa comunidade segura e acolhedora.
                        </p>
                        <div className={style.groupButton}>
                            <button className={style.botaoDenunciar} onClick={()=>handleDenunciar()}>Denunciar</button>
                            <button className={style.botaoCancelar} onClick={()=>{setModalDeletePostagem(false)}}>Cancelar</button>
                        </div>
                    </div>
                :stagio=="stagio3"? //Denunciado com sucesso
                    <div>
                        <p className={style.titulo}>Denuncia enviada com sucesso!</p>
                        <p className={style.corpoDenuncia}>
                        Agradecemos por sua denúncia! Sua contribuição é importante para manter nossa comunidade segura e acolhedora.
                        Nossa equipe de moderadores irá revisar o conteúdo denunciado e tomar as medidas necessárias conforme nossas políticas.
                        Obrigado por nos ajudar a melhorar a plataforma, e fique com Deus.
                        </p>
                        <div className={style.groupButton}>
                            <button className={style.botaoCancelar} onClick={()=>{setModalDeletePostagem(false)}}>Ok</button>
                        </div>
                    </div>
                :stagio=="stagio4"? //Vc já denunciou
                <div>
                    <p className={style.titulo}>Você já denunciou essa postagem!</p>
                    <p className={style.corpoDenuncia}>
                    Não se preocupe, sua denúncia já foi registrada e será revisada com atenção pelos nossos moderadores.
                    Agradecemos por ajudar a manter nossa comunidade segura. 
                    Caso tenha denunciado novamente por ter selecionado o tópico errado, nós arrumaremos para você!
                    Fique com Deus.
                    </p>
                    <div className={style.groupButton}>
                        <button className={style.botaoCancelar} onClick={()=>{setModalDeletePostagem(false)}}>Ok</button>
                    </div>
                </div>    
                : //Erros
                <div>
                    <p className={style.titulo}>Poxa, deu erro =/</p>
                    <p className={style.corpoDenuncia}>
                    Algo deu errado ao tentar processar sua denúncia.
                    Pedimos mil desculpas pelo inconveniente. Sei o quanto é chato dar erro logo na denúncia.
                    Por favor, tente novamente mais tarde.
                    Se o problema persistir, entre em contato com um moderador para obter assistência.
                    Agradecemos sua paciência e compreensão.
                    Saiba disto: estamos sempre averiguando erros, e não demoraremos a concertá-lo.
                    Erro: {erro}
                    Fique com Deus.
                    </p>
                    <div className={style.groupButton}>
                        <button className={style.botaoCancelar} onClick={()=>{setModalDeletePostagem(false)}}>Ok</button>
                    </div>
                </div>         
                }
            </div>
    );
}


export default function ModalDeletePostagemComp({ postagemSelecionadaDados,setListaPostagens,user,setModalDeletePostagem }) {
    const postagem = postagemSelecionadaDados;
    const postador = postagem.postador;
    const [option, setOption] = useState("inicio");
    // Função para deletar a postagem e todas as imagens associadas
    async function deletarPostagem() { 
        try {
            // Verificar se o usuário atual é o dono da postagem, no back é verificado tb
            if (postagem && postador.userUid == user.uid) {
                //Chamar deleta postagem (ja tem try lá)
                await deletarPostagemGlobal(postagem.id);
                window.location.reload();

            } else {
                console.log("Você não tem permissão para deletar esta postagem ou a postagem não existe mais.");
                //N precisa tratar essa exibição, pois o frontend já n exibirá o botão de deletar
            }
        } catch (error) {
            console.log(error);
        }
    };

   

    async function handleUnfollow(uidPostador) {
        const result = await handleSeguirOuNao(user.uid, uidPostador);
        if (result) { //Deu certo
            // Atualize a lista de postagens
            const resultado = result.segue==false?0:1;
            setListaPostagens(prevList => 
                prevList.map(postagem => 
                    postagem.id === postagemSelecionadaDados.id
                    ? { ...postagem, seguindo: resultado } 
                    : postagem
                )
            );
        }
    
        setModalDeletePostagem(false);
    }

    function handleGenerateLink(url) {
        // Pega a URL base
        const baseUrl = window.location.origin;
    
        // Gera a nova URL com o parâmetro da postagem
        const newUrl = `${baseUrl}?p=${url}`;

        // Copia a URL gerada para a área de transferência
        navigator.clipboard.writeText(newUrl).then(() => {
            //Adicionar flutuante aq
            setModalDeletePostagem(false);
        }).catch(err => {
            console.error('Erro ao copiar o link: ', err);
        });
    
    }


    return (
        <>
        <div className={style.fadeBackground} onClick={()=>setModalDeletePostagem(false)}></div>
        <div className={style.container}>            
                {user && postagem && (
                    option === "inicio" ? (
                    <ul className={style.ulOpcoes}>
                        {user.uid !== postador.userUid && //Postagem de terceiro
                            <>
                            <li className={style.redOption} onClick={()=>setOption("denuncia")}>Denunciar</li>
                            {postagem.seguindo==true&&
                                <li className={style.redOption} onClick={()=>handleUnfollow(postador.userUid)}>
                                Deixar de seguir
                                </li>
                            }
                            
                            </>
                        }
                        {user.uid === postador.userUid && //Postagem própria
                            <>
                            <li onClick={deletarPostagem}>Deletar postagem</li>
                            </>
                        }
                        <li onClick={()=>handleGenerateLink(postagem.url)}>Copiar link</li>
                        <li onClick={()=>setModalDeletePostagem(false)}>Cancelar</li>

                    </ul>
                    ) : option === "denuncia" && (
                    <DenunciarModal setOption={setOption} postagemSelecionadaDados={postagemSelecionadaDados} setModalDeletePostagem={setModalDeletePostagem}/>
                    )
                )}
            
        </div>
        </>
    );
}
