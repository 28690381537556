import React from 'react';
import style from './style.module.css';
import { useNavigate } from 'react-router-dom';
import verified from "../../Imgs/verified.png";
function ShowUserLittleProfile({user,naoclicavel,mensagem,limiteCaracteres}) { 
  //"limiteCaracteres" é pra cortar o nome e username
  //Se tiver "mensagem" fica no lugar do username
  //naoClicavel n redireciona ao clicar na foto do perfil
  const navigate = useNavigate();
  const nomeSobrenome = (user.nome + " " + user.sobrenome) || "Usuário do Hevelim";
  const handleClick = () =>{
    //navigate(`/${user.username}`);
    window.location.assign(`/${user.username}`);
  }
  return (
    <div className={style.showuserlittleprofile} onClick={naoclicavel==true?null:handleClick}>
      <img src={user.profile_image?user.profile_image:"https://firebasestorage.googleapis.com/v0/b/socialcity-702b9.appspot.com/o/universal%2Fprofile.jpeg?alt=media&token=cfcad71a-e3a6-43b9-bf0a-95c6e9c23f5c"} alt="Loading" />
      <div className={style.showuserlittleprofileData}>
        <span className={style.flexNomeImg}>
          <p className={style.showuserlittleprofileName}>
          {limiteCaracteres
            ? nomeSobrenome.length > limiteCaracteres 
              ? `${nomeSobrenome.slice(0, limiteCaracteres)}...` 
              : nomeSobrenome
            : nomeSobrenome
          }
          </p>
          {user.pverificado==true?<img src={verified} />:null}
        </span>
        <p className={style.showuserlittleprofileArroba}>
        {
          mensagem
            ? (mensagem.length > 40 ? mensagem.slice(0, 40) + "..." : mensagem)
            : (
              user.username
                ? (limiteCaracteres && user.username.length > limiteCaracteres
                    ? user.username.slice(0, limiteCaracteres) + "..."
                    : user.username
                  )
                : "usuarioDoHevelim"
              )
        }
        </p>
      </div>
    </div>
  );
}

export default ShowUserLittleProfile;
