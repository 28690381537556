import style from "./index.module.css";
import { useState, useEffect } from "react";
import { FiArrowLeft } from 'react-icons/fi';
import { getAuth, sendEmailVerification  } from 'firebase/auth'; // Importar o auth corretamente
import { handleLogout } from "../../global";
import { useNavigate } from 'react-router-dom';
import { FiRefreshCcw } from 'react-icons/fi'; // Para um ícone de refresh

export default function Index({ user }) {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [qtdTentativa, setQtdTentativa] = useState(0);
    const [countdown, setCountdown] = useState();
    const [msgError, setmsgError] = useState("");
    const navigate = useNavigate(); 

    useEffect(()=>{ 

       const savedCountdown = localStorage.getItem('countdown');
       if (savedCountdown) {
           setCountdown(Number(savedCountdown));
           setIsButtonDisabled(Number(savedCountdown) > 0); // Desabilitar botão se countdown > 0
       }else{
        setCountdown(0);
       }
    },[])
    useEffect(() => {
        let timer;
        if (isButtonDisabled) {
            timer = setInterval(() => {
                setCountdown(prevCountdown => {
                    if (prevCountdown <= 1) { //Caso countdown chegue a 0, limpar erros e habilitar botao
                        clearInterval(timer);
                        setmsgError("");
                        setIsButtonDisabled(false);
                        localStorage.removeItem('countdown'); // Limpar o countdown do localStorage
                        return 60;
                    }
                    const newCountdown = prevCountdown - 1;
                    localStorage.setItem('countdown', newCountdown); // Salvar novo countdown no localStorage
                    return prevCountdown - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [isButtonDisabled]);

    const sendEmailVerificationFunction = async () => {
        const auth = getAuth(); // Obtendo o objeto auth
        const currentUser = auth.currentUser; // Usando o auth para obter o usuário atual
        if (currentUser) {
            try {
                await sendEmailVerification(currentUser);
                console.log('Email de verificação enviado com sucesso.');
            } catch (error) {
                if (error.code === 'auth/too-many-requests') {
                    setCountdown(200);
                    setmsgError('Você enviou muitas solicitações. Por favor, aguarde um momento antes de tentar novamente ou tente entrar com o google.');
                } else {
                    setmsgError('Erro ao enviar email de verificação: ' + error.message);
                }
            }
        } else {
            console.error('Usuário não está autenticado.');
        }
    };

    const handleClickSendEmail = (event) => {
        event.preventDefault();
        setQtdTentativa(prevState => prevState + 1);
        setIsButtonDisabled(true);
        sendEmailVerificationFunction();
    };

    return (
        <div className={style.container}>
            <div className={style.content}>
                <div className={style.voltar} onClick={() => handleLogout(navigate)}>
                    <FiArrowLeft size={24} color="#007bff"/>Sair
                </div>
                {
                  isButtonDisabled?
                  <p className={style.infoText}>Um email foi enviado para <span className={style.email}>{user.email}</span></p>
                  :
                  <p className={style.infoText}>Enviar email para <span className={style.email}>{user.email}</span></p>
                }
                  
                  <form className={style.form}>
                      <label htmlFor="newEmail" className={style.label}>
                        Basta clicar no link que chegar no seu email. Eu sei que é chato, mas é para sua segurança. Verifique também a caixa de spam.
                        Após isso, atualize a página.
                        <span className={style.atualizarContainer}>
                          <span className={style.atualizarSpan} onClick={() => window.location.reload()}>
                            <FiRefreshCcw className={style.iconAtualizar}/>
                          </span>
                        </span>

                      </label>
                      {
                          isButtonDisabled ?
                          <button disabled={isButtonDisabled} className={style.buttonDisabled}>Enviar novamente {countdown}</button>
                          :
                          <button disabled={isButtonDisabled} onClick={handleClickSendEmail} className={style.button}>Enviar email</button>
                      }
                    <p style={{ color: 'red' }}>{msgError}</p>
                  </form>
            </div>
        </div>
    );
}
