import { FaPlay, FaVolumeMute, FaVolumeUp } from 'react-icons/fa';
import { useRef, useEffect, useState } from 'react';
import style from "./style.module.css";
export default function ShowVideoShorts({ postagem, videoRefs,globalMuteVideo, setGlobalMuteVideo,globalPauseVideo }) {
    const videoRef = useRef(null); // Referência para o elemento de vídeo
    const [isPlaying, setIsPlaying] = useState(true); // Estado para controlar se o vídeo está tocando
    
    useEffect(() => {
        // Sincroniza a mutagem global com o vídeo atual
        if (videoRef.current) {
            videoRef.current.muted = globalMuteVideo;
        }
    }, [globalMuteVideo]);

    useEffect(()=>{
        if(globalPauseVideo){
            if(videoRef.current){
                setIsPlaying(false);
                videoRef.current.pause();
            }
        } //N pode usar else, pois senão todos vídeos vão dar play, afinal é global. Assim tá ótimo.
    },[globalPauseVideo])


    useEffect(() => { // Adiciona o vídeo atual à lista de referências
        if (videoRef.current) {
          videoRefs.current.push(videoRef.current);
        }
    }, [videoRefs]);

    function checkVideoAspectRatio(file) { //Embora n tenha async é uma função assincrona! use await!
        return new Promise((resolve, reject) => {
          const video = document.createElement('video');
          video.src = URL.createObjectURL(file);
      
          video.onloadedmetadata = () => {
            // Obtém as dimensões do vídeo
            const width = video.videoWidth;
            const height = video.videoHeight;
      
            // Calcula a proporção
            const aspectRatio = width / height;
      
            // Proporções permitidas
            const ratios = {
              "9:16": 9 / 16,
              "2:3": 2 / 3,
              "4:5": 4 / 5
            };
      
            // Verifica se a proporção calculada corresponde a alguma das permitidas
            for (const [label, ratio] of Object.entries(ratios)) {
              // Tolerância para variações pequenas na proporção
              if (Math.abs(aspectRatio - ratio) < 0.01) {
                resolve({ isValid: true, ratio: label });
                return;
              }
            }
      
            resolve({ isValid: false, ratio: null });
          };
      
          video.onerror = (error) => {
            reject(error);
          };
        });
    }
    const toggleMute = () => {
        if (videoRef.current) {
            const newMuteState = !globalMuteVideo;
            setGlobalMuteVideo(newMuteState); // Atualiza o estado global de mutagem
        }
    };
  
    const togglePlay = () => {
      if (videoRef.current) {
        if (isPlaying) {
          videoRef.current.pause();
        } else {
          videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
      }
    };

    return (
      <div className={style.divVideo} key={postagem.id}>
        <video
          onClick={togglePlay}
          ref={videoRef}
          className={style.video}
          src={postagem.video.caminhoImagem}
          preload="metadata"
          muted={globalMuteVideo}
          loop
          onPlay={()=>setIsPlaying(true)}
          onPause={()=>setIsPlaying(false)}
        />
        <div onClick={togglePlay} className={style.playButton}>
            {!isPlaying &&<FaPlay className={style.iconePlay}/>}
        </div>
        <div onClick={toggleMute} className={style.muteButton}>
            {globalMuteVideo ? <FaVolumeMute className={style.iconeMute} /> : <FaVolumeUp className={style.iconeMute} />}
        </div>
      </div>
    );
  }