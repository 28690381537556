import React, { useState, useEffect, useRef  } from 'react';
import style from './index.module.css';
import ModalDeletePostagemComp from '../ShowPostagens/ModalDeletePostagem';
import ShowModalPostagemUnica from '../ModalPostagemUnica';
import ShowVideoShorts from './ShowVideoShorts';
import MenuEsquerdo from "../MenuEsquerdo";
import {fetchAndSetPostagens} from '../../global';
import loadingGif from "../../Imgs/loadingIcon.gif"


// Componente PostagemCorpo PS: Não pode juntar ele com o showpOstagens, dá vontade, mas tira isso da cabeça q dá lenha
function PostagemCorpo({postagem, globalMuteVideo,setGlobalMuteVideo,globalPauseVideo, handlerModalDeletePostagem, setPostagemSelecionadaDados, setListaPostagens, setShowModalPostagemUnicaHandler, user }) {
    const videoRefs = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            const videoElement = entry.target;
            if (entry.isIntersecting) {
                videoElement.preload = "auto";
                videoElement.play();
            } else {
                videoElement.preload = "metadata";
                videoElement.pause();
            }
          });
        }, { threshold: 0.7 }); //Porcentagem q tem q ta visivel na viewport pra executar o comendo (no caso, play).
    
        videoRefs.current.forEach(video => observer.observe(video));
    
        return () => {
          videoRefs.current.forEach(video => observer.unobserve(video));
        };
      }, [globalMuteVideo]);

    return (
        <>
            {postagem.video && <ShowVideoShorts handlerModalDeletePostagem={handlerModalDeletePostagem} setPostagemSelecionadaDados={setPostagemSelecionadaDados} setListaPostagens={setListaPostagens} setShowModalPostagemUnicaHandler={setShowModalPostagemUnicaHandler} user={user} postagem={postagem} videoRefs={videoRefs} globalMuteVideo={globalMuteVideo} setGlobalMuteVideo={setGlobalMuteVideo} globalPauseVideo={globalPauseVideo}/> }
        </>
    );
}
// Componente Principal ShowPostagens
export default function ShowPostagens({user, setUser}) { 

    const [modalDeletePostagem, setModalDeletePostagem] = useState(false);//Controla abertura modal deletar postagem
    const [postagemSelecionadaDados, setPostagemSelecionadaDados] = useState([]); //A postagem q selecionar (no click) pra abrir no modal postagem única
    const [ShowModalPostagemUnicaHandler, setShowModalPostagemUnicaHandler] = useState(false);
    const [globalMuteVideo,setGlobalMuteVideo] = useState(true); //Muta todos os videos na timeline
    const [globalPauseVideo, setGlobalPauseVideo] = useState(false); //Pausa todos os videos na timeline, usado pra qd abrir modal
    const [offset, setOffset] = useState(0); //Offset pra load de postagens.
    const [limit, setLimit] = useState(10); //Limite de postagens loadadas por vez
    const [loadMore, setLoadMore] = useState(false); //Se true, é gatilho pra fazer load de postagem.
    const [loading, setLoading] = useState(true); //Se false quando já tiver fzd requisicao pra load de postagem, usado pra n fzer varias requisicoes
    const [atingiuFimPostagens, setAtingiuFimPostagens] = useState(false);//Fica true ao scrollar pro final da página
    const [errorLoadPost, setErrorLoadPost] = useState(""); //Exibe erro se não conseguir fazer load das postagens
    const [listaPostagens, setListaPostagens] = useState([]); //Guarda todas as postagens, assim como dados do postador.

    useEffect(()=>{ //Load de postagens iniciais
        setLoadMore(true); //N pode inicializar como true na hr de criar, tem q mudar pra true pra fazer load! Se n da lenha.
    },[])

    useEffect(()=>{ //Load de postagens
    async function pegarDados(){
        try{
            const postagensNovas = await fetchAndSetPostagens('',"everypost",offset,limit,user.uid,"shorts");
            setListaPostagens(prevPostagens => [...prevPostagens, ...postagensNovas]);
            if (postagensNovas.length < limit) {
            setAtingiuFimPostagens(true); // Define que atingiu o fim se menos postagens forem retornadas
            }
        } catch(error){
        setErrorLoadPost(`Ocorreu um erro ao carregar as postagens. Lamentamos pelo inconveniente e já estamos trabalhando para resolver o problema. Agradecemos muito sua compreensão.🙏`)
        }finally{
        setOffset(prevOffset => prevOffset + limit);
        setLoadMore(false);
        }
    }
    if(loadMore===true && user){
        pegarDados();
    }
    },[loadMore, user])

    useEffect(()=>{
        //Mutar videos ao abrir postagem unica modal.
        if(ShowModalPostagemUnicaHandler){
            setGlobalPauseVideo(true);
        }else{
            setGlobalPauseVideo(false);
        }
    },[ShowModalPostagemUnicaHandler])
    
    const postagensRef = useRef(null);
    useEffect(() => { //Captar scroll
      const handleScroll = () => {
          if (postagensRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = postagensRef.current;
            if(atingiuFimPostagens){
              console.log("Fimm");
            }else{
              if (scrollTop + clientHeight >= scrollHeight - 100 && loading) { 
                console.log("vai pokemon");
                setLoadMore(true); //Ativa listener pra pegar mais posts
                setLoading(false); //Impede de chamar dnv
                setTimeout(() => { //Dps de 3 secs ativa a possibilidade de load dnv
                  setLoading(true);
                }, 1000);
              } 
            }
            
          }
      };
  
      const div = postagensRef.current;
      if (div) {
        div.addEventListener('scroll', handleScroll);
      }
  
      return () => {
        if (div) {
          div.removeEventListener('scroll', handleScroll);
        }
      };
    },[loading, loadMore]); 

    const [scrollTop, setScrollTop] = useState(0); //Para guardar scroll
    const handleScroll = (event) => {
        setScrollTop(event.target.scrollTop); // Atualiza o estado com a posição do scroll
    };
        
    
    
    
    function handlerModalDeletePostagem(postagemSelecionada) {
        setPostagemSelecionadaDados(postagemSelecionada)
        setModalDeletePostagem(true);
    }

    return (
        <React.Fragment>
        <div className={style.container} onScroll={handleScroll} ref={postagensRef}>
          <div className={style.espaco_esquerdo}>{<MenuEsquerdo user={user} setUser={setUser}/>}</div> {/*Grid espaco-esquerdo*/}
          <div className={style.espaco_central}> {/*Grid espaco-central*/}
            <div className={style.stories}></div>
            <div className={style.postagens} >
              {user&&
              
              <div className={style.todasPostagens}>
  
                  {ShowModalPostagemUnicaHandler && user && (<ShowModalPostagemUnica setPostagemSelecionadaDados={setPostagemSelecionadaDados} postagemSelecionadaDados={postagemSelecionadaDados} setShowModalPostagemUnicaHandler={setShowModalPostagemUnicaHandler} user={user}setListaPostagens={setListaPostagens} midia={false}/>)}
                  {modalDeletePostagem && <ModalDeletePostagemComp postagemSelecionadaDados={postagemSelecionadaDados} user={user} setModalDeletePostagem={setModalDeletePostagem}/>}
  
                  {listaPostagens.map((postagem, index) => (
                      <div className={style.postagem} key={`postagem-${index}`}>
                          <PostagemCorpo scrollTop={scrollTop} postagem={postagem} globalMuteVideo={globalMuteVideo}setGlobalMuteVideo={setGlobalMuteVideo}globalPauseVideo={globalPauseVideo} handlerModalDeletePostagem={handlerModalDeletePostagem} setPostagemSelecionadaDados={setPostagemSelecionadaDados} setListaPostagens={setListaPostagens} setShowModalPostagemUnicaHandler={setShowModalPostagemUnicaHandler} user={user}/>
                      </div>
                  ))}
              </div>
                
              
              }
  
              {!loading&&<div className={style.divGift}><img src={loadingGif} className={style.gifLoading}/></div>}
              
              {atingiuFimPostagens && (
                //Visualizou todas as postagens
                offset >0&& <p className={style.fimPostagens}>Nada mais por aqui. Que tal seguir algumas páginas?</p>
              )}
              {errorLoadPost&&
                <p className={style.errorMessage}>{errorLoadPost}</p>
              }
              
            </div>
          </div>
        </div>
        </React.Fragment>
    );
}


