import React, { useState, useEffect, useRef  } from 'react';
import style from './index.module.css';
import { AiOutlineClockCircle, AiOutlineComment } from 'react-icons/ai';
import { RiSendPlaneLine } from 'react-icons/ri';
import {FaHeart, FaRegHeart } from 'react-icons/fa';
import DescricaoComMais from '../DescricaoComMais/DescricaoComMais';
import ModalDeletePostagemComp from './ModalDeletePostagem';
import ShowModalPostagemUnica from '../ModalPostagemUnica';
import {capitalizeFirstLetter, calcularTempoDecorrido, obterToken, handleShare} from '../../global';
import apiUrl from "../../global";
import axios from 'axios';
import ShowLittleProfileModal from "../ShowLittleProfileModal";
import { useNavigate } from 'react-router-dom';
import Carousel from "./Carousel";
import ShowVideoShorts from './ShowVideoShorts';
import verified from "../../Imgs/verified.png";
// Componente PostagemHeader
function PostagemHeader({ postagem, handlerModalDeletePostagem }) {
    const [onHoverName, setOnHoverName] = useState(false);
    const navigate = useNavigate();
    const handleNavigate = (username) => {
        navigate(`/${username}`);
    };
    const postador = postagem.postador;
    const nomeSobrenome = capitalizeFirstLetter(postador.nome) + " " +capitalizeFirstLetter(postador.sobrenome);

    return (
        <div>
            <div className={style.postagemHeader}>
                <img className={style.postagemHeaderImg} src={postador.profile_image} alt="User" />
                <div className={style.nameAndUsername} onMouseEnter={()=>setOnHoverName(true)}>
                    <span className={style.modalSpan} onMouseLeave={()=>setOnHoverName(false)}>
                        {onHoverName &&
                            <div className={style.modalStyle}>
                                <ShowLittleProfileModal postagem={postagem} limiteCaracteres={20}/>
                            </div>
                        }
                        <span className={style.postagemName} onClick={()=>handleNavigate(postador.username)}>
                        <p>
                        {nomeSobrenome.length > 20
                            ? nomeSobrenome.slice(0, 20) + ".." 
                            : nomeSobrenome}
                        </p>
                        {postador.pverificado==true?<img src={verified} />:null}
                        </span>
                    </span>
                    <p className={style.postagemUserName}>
                    @{postador.username.length > 20 
                        ? postador.username.slice(0, 20) + "..." 
                        : postador.username}
                    </p>
                </div>
                
                <span className={style.postagemTimeIcon}><AiOutlineClockCircle /></span>
                <p className={style.postagemHorario}>{calcularTempoDecorrido(postagem.criadoEm)}</p>
                <p className={style.deletarPostagem} onClick={() => handlerModalDeletePostagem(postagem)}>...</p>
            </div>
        </div>
    );
}
// Componente PostagemInteracoes
function PostagemInteracoes({postagem,userId,setListaPostagens,setShowModalPostagemUnicaHandler,setPostagemSelecionadaDados}) {
    const postagemId = postagem.id;
    let isRunningHandleLike = false;
    async function handleLike(){
        if(isRunningHandleLike) return;
        isRunningHandleLike=true;

        const acao = postagem.curtido?"descurtir":"curtir";
        const token = await obterToken();
        try {
            
            const response = await axios.post(`${apiUrl}/curtirPostagem`, 
            { postagemId, acao }, // Corpo da requisição
            { 
                headers: {
                'Authorization': `Bearer ${token}`, // Inclui o token no cabeçalho
                'Content-Type': 'application/json' // Garante que o corpo da requisição está no formato JSON
                }
            }
            );

          const success = response.data.success; //recebe true ou false
          if(success){
            setListaPostagens(prevLista => 
                prevLista.map(posts => 
                    posts.id === postagemId
                    ? {
                        ...posts,
                        quantidadeDeCurtidas: posts.quantidadeDeCurtidas + (!posts.curtido==true?1:-1),
                        curtido: !posts.curtido
                      }
                    : posts
                )
            );
          }
    
        } catch (error) {
          console.error('Erro ao curtir/descurtir postagem:', error);
        }finally{
            isRunningHandleLike=false;
        }
        
    }
    function onClickComments(){
        setPostagemSelecionadaDados(postagem);
        setShowModalPostagemUnicaHandler(true);
    }
    return (
        <span>
        <div className={style.interacoes}>
            <span onClick={handleLike} className={style.cursorPointer}>
                 {postagem.curtido?<FaHeart className="icon" color="red" />:<FaRegHeart className="icon" />}
            </span>
            <span onClick={onClickComments} className={style.cursorPointer}><AiOutlineComment className="icon" /></span>
            <span className={style.cursorPointer} onClick={()=>handleShare(postagem.descricao,postagem.url)}><RiSendPlaneLine className="icon" /></span>
            </div>
            <div className={style.descricaoPostagem}>
                {postagem.descricao&&
                <span>
                    <p>{postagem.postador.username}:</p>
                    <DescricaoComMais descricao={postagem.descricao} limite="200"/>
                </span>
                }
            </div>
        <hr className={style.hrQuebraPostagem}/>
        </span>
    );
}
// Componente PostagemCorpo
function PostagemCorpo({ postagem, globalMuteVideo,setGlobalMuteVideo,globalPauseVideo }) {
    const videoRefs = useRef([]);

    useEffect(() => { //Listener intersection para dar play no video automaticamente
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            const videoElement = entry.target;
            if (entry.isIntersecting) {
                videoElement.preload = "auto";
                videoElement.play();
            } else {
                videoElement.preload = "metadata";
                videoElement.pause();
            }
          });
        }, { threshold: 0.6 }); //Porcentagem q tem q ta visivel na viewport pra executar o comendo (no caso, play).
    
        videoRefs.current.forEach(video => observer.observe(video));
    
        return () => {
          videoRefs.current.forEach(video => observer.unobserve(video));
        };
      }, [globalMuteVideo]);

    return (
        <>
            {postagem.imagens.length>0? <div className={style.limitaCarousel}><Carousel postagem={postagem}/></div> : postagem.video && <ShowVideoShorts postagem={postagem} videoRefs={videoRefs} globalMuteVideo={globalMuteVideo} setGlobalMuteVideo={setGlobalMuteVideo} globalPauseVideo={globalPauseVideo}/> /*Cria vídeo se for video */}
        </>
    );
}
// Componente Principal ShowPostagens
export default function ShowPostagens({ listaPostagens,setListaPostagens, user}) { 

    /*
    postagemSelecionadaDados ou postagem(dentro de map) -> postagem específica
    listaPostagens -> todas as postagens
    */
    const [modalDeletePostagem, setModalDeletePostagem] = useState(false);//Controla abertura modal deletar postagem
    const [postagemSelecionadaDados, setPostagemSelecionadaDados] = useState([]); //A postagem q selecionar (no click) pra abrir no modal postagem única
    const [showModalPostagemUnicaHandler, setShowModalPostagemUnicaHandler] = useState(false);
    const [globalMuteVideo,setGlobalMuteVideo] = useState(true); //Muta todos os videos na timeline
    const [globalPauseVideo, setGlobalPauseVideo] = useState(false); //Pausa todos os videos na timeline, usado pra qd abrir modal

    useEffect(()=>{
        //Mutar videos ao abrir postagem unica modal.
        if(showModalPostagemUnicaHandler){
            setGlobalPauseVideo(true);
        }else{
            setGlobalPauseVideo(false);
        }
    },[showModalPostagemUnicaHandler])
    
    function handlerModalDeletePostagem(postagemSelecionada) {
        setPostagemSelecionadaDados(postagemSelecionada)
        setModalDeletePostagem(true);
    }

    return (
        <div className={style.todasPostagens}>

            {showModalPostagemUnicaHandler && user && (<ShowModalPostagemUnica setPostagemSelecionadaDados={setPostagemSelecionadaDados} postagemSelecionadaDados={postagemSelecionadaDados} showModalPostagemUnicaHandler={showModalPostagemUnicaHandler} setShowModalPostagemUnicaHandler={setShowModalPostagemUnicaHandler} user={user}setListaPostagens={setListaPostagens} midia={true}/>)}
            {modalDeletePostagem && <ModalDeletePostagemComp postagemSelecionadaDados={postagemSelecionadaDados} setListaPostagens={setListaPostagens} user={user} setModalDeletePostagem={setModalDeletePostagem}/>}

            {listaPostagens.map((postagem, index) => (
                <div className={style.postagem} key={`postagem-${index}`}>
                    <PostagemHeader postagem={postagem} handlerModalDeletePostagem={handlerModalDeletePostagem}/>
                    <PostagemCorpo postagem={postagem} globalMuteVideo={globalMuteVideo}setGlobalMuteVideo={setGlobalMuteVideo}globalPauseVideo={globalPauseVideo}/>
                    <PostagemInteracoes postagem={postagem} userId={user.uid} setListaPostagens={setListaPostagens} setShowModalPostagemUnicaHandler={setShowModalPostagemUnicaHandler} setPostagemSelecionadaDados={setPostagemSelecionadaDados}/>
                </div>
            ))}
        </div>
    );
}


