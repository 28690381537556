import styles from "./politicaPrivaci.module.css";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from 'react-icons/fa'; // Seta para a direita da Font Awesome

export default function Index() {
    const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <aside className={styles.sidebar}>
        <ul>
          <li className={styles.voltarLi} onClick={()=>navigate("/")}>
            <FaArrowLeft/>
            PÁGINA INICIAL
          </li>
          <li><a href="#politicaPrivacidade">Política de privacidade</a></li>
          <li><a href="#protecaoDados">Proteção dos dados</a></li>
          <li><a href="#conduta">Política de Conteúdo e Conduta</a></li>
          <li><a href="#postar">Saiba o que postar</a></li>
          <li><a href="#direitosautorais">Direitos autorais</a></li>
          <li><a href="#alcance">Como aumentar meu alcance</a></li>
        </ul>
      </aside>
      <main className={styles.content}>
        <section id="politicaPrivacidade" className={styles.section}>
          <h2>Política de privacidade</h2>

          <p className={styles.title}>1 - Acesso de Terceiros: </p>
          <p>
          Terceiros têm acesso apenas às informações básicas dos usuários que são exibidas nos perfis, incluindo nome, sobrenome, imagem de perfil e postagens públicas. Não têm acesso a dados sensíveis, como e-mail, data de nascimento, número de telefone ou qualquer outra informação pessoal e confidencial.
          Isso garante que a privacidade e a segurança dos nossos usuários sejam sempre priorizadas, mantendo informações pessoais protegidas de acesso não autorizado.
          </p>


          <p className={styles.title}>2 - Deleção de Contas: </p>
          <p>
          Após a solicitação de deleção de conta, há um período de 20 dias antes do perfil ser completamente deletado. Durante esse tempo, nenhum dado, nem mesmo nome e sobrenome, são exibidos ao público.
          O usuário pode escolher cancelar a deleção da conta, basta realizar login e confirmar "cancelar exclusão".
          Após a exclusão, todos os dados que antes estavam ocultos são deletados, incluindo informações básicas, registros, postagens e comentários.
          </p>

          <p className={styles.title}>3 - Coleta de dados fora da plataforma</p>
          <p>
          Não coletamos dados dos usuários fora da nossa plataforma. Todas as informações de navegação, interações e dados pessoais são mantidos estritamente dentro do ambiente do nosso site, garantindo a privacidade e segurança dos nossos usuários.
          </p>

        </section>

        <section id="protecaoDados" className={styles.section}>
          <h2>Proteção dos dados dos usuários</h2>
          <p className={styles.title}>1 - Criptografia</p>
          <p>
             Utilizamos criptografia para garantir que apenas usuários autorizados possam acessar dados sensíveis. Isso significa que as informações dos usuários só podem ser acessadas por aqueles que possuem um token de acesso.
          </p>

          <p className={styles.title}>2 - Backups Regulares</p>
          <p>
           Realizamos backups regulares automáticamente dos dados para evitar perda de informações em caso de incidentes ou falhas.
          </p>
        </section>
        
        <section id="conduta" className={styles.section}>
          <h2>Política de Conteúdo e Conduta</h2>
          <p className={styles.title}>1 - Denuncias</p>
          <p>
          Utilizamos um robusto sistema de denúncias onde cada conteúdo denunciado é revisado por um moderador. Eles têm a capacidade de excluir postagens ou comentários e, em casos mais graves, banir permanentemente o usuário da plataforma.
          Nosso sistema garante que, se um usuário deletar uma postagem (vídeo ou imagem) ou comentário que recebeu denúncias, o item será imediatamente ocultado para todos os usuários. No entanto, o item só será realmente deletado após os moderadores avaliarem a denúncia. Isso evita que usuários publiquem conteúdo inadequado e o removam rapidamente na tentativa de poluir a plataforma.
          Após o moderador revisar o conteúdo, independente da decisão tomada, o conteúdo será automaticamente deletado. Realizamos avaliações de denúncias diariamente, buscando sempre manter a plataforma limpa.
          Além disso, utilizamos um algoritmo avançado para ajudar na detecção de conteúdo impróprio, acelerando o processo de moderação e garantindo que qualquer material inadequado seja identificado e tratado rapidamente.
          </p>

          <p className={styles.title}>2 - Comentários e postagens</p>
          <p>
          Ao acessar uma postagem, você pode notar que, às vezes, há menos comentários exibidos do que o número listado no topo. Isso ocorre porque nem todos os comentários são visíveis para os usuários.
          Os moderadores têm a capacidade de excluir comentários denunciados que não estejam de acordo com as políticas da plataforma. Contudo, além disso, eles podem ocultar comentários denunciados que, embora não sejam ofensivos de forma direta, possam causar desconforto ou que não sejam adequados para o contexto da plataforma, como comentários possivelmente gerados por bots, com aparente cunho malicioso ou de aparente duplo sentido.
          Os comentários ocultos não são vistos por usuários comuns na plataforma como uma medida de segurança. 
          Do mesmo modo, postagens que não são ofensivas mas que não fazem parte da proposta da plataforma, após serem denunciadas, podem serem realocadas da timeline
          para ser exibida apenas no perfil de quem postou e das pessoas que o seguem, como, por exemplo, venda de produtos. Veja: <a href="#postar"> O que postar. </a>
          Contudo, não existem postagens ocultas: ou são exibidas ao publico, ou apenas aos seguidores/amigos/perfil pessoal.
          </p>
        </section>

        <section id="postar" >
          <h2>Diretrizes de Postagem na Rede Social Cristã</h2>
          <p className={styles.title}>1 - O que postar?</p>
          <p>
          No Hevelim, você pode compartilhar uma variedade de conteúdos, desde que não infrinja os princípios éticos e morais que aprendemos com Cristo.
          Além disso, é importante diferenciar o que é apropriado compartilhar na sua rede pessoal para seus seguidores, do que é recomendado postar para o público geral. Enquanto posts pessoais podem incluir uma ampla gama de assuntos cotidianos, os posts globais devem ter um foco maior em temas cristãos, para manter o objetivo e a essência da plataforma.
          </p>

            <p className={styles.title}>Exemplo de postagens válidas para seus seguidores, seu perfil e seus amigos:</p>
            <p>- Cuidado com a saúde (dieta, academia, etc.)</p>
            <p>- Atividades esportivas (futebol, corridas, etc.)</p>
            <p>- Experiências do dia a dia (trabalho, escola)</p>
            <p>- Dicas e conselhos (alimentação, estudos, etc.)</p>
            <p>- Basicamente qualquer aspecto da sua vida que você queira compartilhar</p>

            <p className={styles.title}>Exemplos de postagens válidas para o público geral:</p>
            <p>- Ensinamentos bíblicos e reflexões espirituais</p>
            <p>- Dicas de filmes e séries com temas cristãos</p>
            <p>- Fotos e relatos de atividades na igreja ou eventos comunitários</p>
            <p>- Citações inspiradoras de pensadores e filósofos cristãos</p>
            <p>- Conselhos e dicas baseadas em valores cristãos</p>
            <p>- Testemunhos pessoais sobre fé e experiências espirituais</p>
            <p>- Atualizações e novidades sobre projetos comunitários ou missionários</p>

            <p className={styles.title}>Além disso, você pode postar para o público geral:</p>
            <p>Não necessariamente precisa ser algo que tenha relação com a igreja, colocar em prática ensinamentos cristãos são muito bem-vindos:</p>
            <p>- Postar fotos/vídeos ajudando animais de rua, fornecendo água e ração</p>
            <p>- Postar imagens e vídeos plantando árvores na cidade</p>
            <p>- Fazer doações para instituições de caridade ou para famílias necessitadas</p>
            <p>- Participar de campanhas de arrecadação de alimentos e roupas para os mais necessitados, documentando a campanha</p>
            <p>- Voluntariar-se em lares de idosos, hospitais ou orfanatos, compartilhando imagens e vídeos das atividades</p>
            <p>- Organizar ou participar de mutirões de limpeza em bairros e parques, registrando com fotos e vídeos para postagens</p>
            <p>- Promover e participar de eventos comunitários e atividades recreativas saudáveis, documentando a participação</p>

            <p className={styles.title}>O que acontece se eu postar para todos algo que eu deveria postar só para meus seguidores?</p>
            <p>
                Nossos moderadores podem realocar sua postagem de "para todos" para "para seus seguidores e amigos" caso isso venha a acontecer, então não precisa se preocupar.
                Contudo, tome cuidado para que isso não se torne recorrente para que seu perfil não seja penalizado, como ficar sem postar por determinado período de tempo.
                Exemplo de postagens que não devem serem postadas como "para todos":
            </p>
            <p>- Propaganda política</p>
            <p>- Venda de produtos ou serviços</p>
            <p>- Anúncios de aluguel ou imóveis</p>
            <p>- Conteúdos que são mais pessoais e não têm relevância para o público geral</p>
            


        </section>

        <section id="direitosautorais" >
          <h2>Direitos autorais</h2>
          <p className={styles.title}>Músicas, filmes e clipes</p>
          <p>
          Aqui na Hevelim, entendemos que o conceito de fair use é importante para a criação de conteúdo, especialmente ao utilizar pequenos trechos de músicas ou vídeos. Por isso, nossa plataforma permite:
          </p>
          <p>- Até 12 segundos de uma música de fundo.</p>
          <p>- Até 10 segundos de videoclipe ou cenas de filmes, séries, animes e desenhos.</p> 
          <p>Contudo, esses trechos não devem comprometer o interesse do usuário em assistir ao conteúdo original, especialmente em vídeos com reviravoltas ou finais únicos.
          É importante ressaltar que essas diretrizes são baseadas em nossa interpretação do fair use no Brasil, pois a legislação não define um limite exato de tempo. Mesmo assim, a lei deve ser respeitada. Se algum conteúdo infringir a lei de direitos autorais e houver uma solicitação de remoção, cumpriremos imediatamente, independente de quantos segundos são.
          Além disso, caso uma postagem utilize mais de 12 segundos de música ou 10 segundos de vídeo/filme e seja denunciada por um usuário, a postagem poderá ser excluída da plataforma.
          Em resumo, temos que:
          </p>
          <p>1 - Você pode utilizar até 12 segundos de música de fundo em seus vídeos e até 10 segundos de clipe ou filme, e sua postagem não será deletada por isso. No entanto, se o detentor dos direitos autorais solicitar a remoção, a postagem será excluída. Caso um usuário comum faça a solicitação, a postagem permanecerá na plataforma.</p>
          <p>2 - Você utilizou 13 segundos de música de fundo e um usuário comum denunciou, sua postagem será removida.</p>
          <p>3 - A Hevelim não se responsabiliza por conteúdos que infringem direitos autorais e se reserva o direito de remover postagens conforme necessário.</p>
          <p>4 - A plataforma pode revisar conteúdos a qualquer momento e agir em conformidade com as leis de direitos autorais.</p>
          <p>5 - É possível que uma postagem não ultrapasse os limites de uso da plataforma e ainda assim seja excluída. Isso ocorre devido à legislação de direitos autorais no Brasil, que considera o contexto e a intenção de uso. Mesmo que você utilize trechos permitidos de músicas ou vídeos, a lei prevê que o uso deve ser feito de forma que não prejudique os direitos do autor.
          </p>
            <p className={styles.title}>Exemplo:</p>
            <p className={styles.title}>Uso do Conteúdo:</p>
            <p>
              A utilização de trechos de músicas ou vídeos deve ser justificada por um propósito claro, como crítica, paródia ou comentário. Se o conteúdo for interpretado como uma substituição da obra original, a postagem pode ser considerada uma violação.
            </p>
            <p className={styles.title}>
            Contexto e Repercussão:</p>
            <p> O impacto que o uso do conteúdo tem sobre o mercado da obra original também é considerado. Se a postagem for vista como prejudicial ao valor comercial da música ou do vídeo, mesmo o uso de trechos curtos pode ser contestado.
            </p>

            <p className={styles.title}>Geralmente é seguro:</p>
            <p>1. Utilizar trechos curtos de músicas (até 12 segundos) para introduzir ou apresentar o tema de um vídeo.</p>
            <p>2. Fazer comentários ou críticas a uma música, incluindo até 12 segundos de trechos relevantes.</p>
            <p>3. Usar até 10 segundos de um clipe de filme como parte de uma análise ou resenha.</p>
            <p>4. Criar compilações de apresentações musicais, desde que cada trecho não ultrapasse os limites estabelecidos.</p>
            <p>5. Realizar paródias ou remixes que transformem o conteúdo original e o utilizem de forma criativa.</p>
            <p>6. Produzir tutoriais ou vídeos educacionais que utilizem pequenos trechos de músicas para fins de demonstração.</p>
            <p>7. Exibir músicas em contextos de discussão, como podcasts ou vídeos de opinião, sem que o foco principal seja a música.</p>

            <p className={styles.title}>Dica da plataforma Hevelim</p>
            <p>
              A legislação de direitos autorais no Brasil em relação ao fair use não é tão clara quanto em outros países. Embora o uso de pequenos trechos de músicas geralmente não cause problemas, é prudente estar preparado para possíveis solicitações de remoção. Por isso, recomendamos que você mantenha um backup da sua postagem, permitindo que você altere a música facilmente e repostar, se necessário. Além disso, considere utilizar músicas que estejam livres de direitos autorais para garantir a segurança do seu conteúdo.
            </p>

        </section>

        <section id="alcance" >
          <h2>Como aumentar meu alcance</h2>
          <p className={styles.title}>1 - Formato correto para shorts</p>
          <p>
            Nem todo vídeo postado vira shorts, e nem todo vídeo que vira shorts tem o mesmo alcance.
            Para postar um vídeo, você pode utilizar os seguintes formatos verticais:
            9:16, 1:1, 2:3, 3:4, 4:5.
            Contudo, sempre damos prioridade ao formato 9:16 por ser o formato ideal para shorts, sem tela preta nas laterais
            ou na parte superior/inferior, tornando a opção de maior alcance.
          </p>

          <p className={styles.title}>2 - Frequência de postagens</p>
          <p>
            Um dos principais fatores para melhorar seu alcance é a frequência nas suas postagens. O cálculo do alcance considera as publicações dos últimos 7 dias. No entanto, não basta postar qualquer conteúdo; levamos em conta a proporção de reações em relação às visualizações, incluindo curtidas, comentários e interações. 
            Em resumo: poste com mais regularidade e foque na qualidade do conteúdo para aumentar seu alcance. Além disso, evite o uso de bots. Embora possam gerar um aumento nas reações, temos algoritmos para detectar atividades fraudulentas. 
            NÃO PAGUE POR BOTS — isso pode não só desperdiçar seu dinheiro, mas também prejudicar seu desempenho na plataforma.
          </p>

          <p className={styles.title}>3 - Perfil verificado</p>
          <p>
           Um perfil verificado oferece diversas vantagens, sendo uma delas o aumento no alcance das postagens. Isso contribui para a segurança da plataforma, pois os usuários da plataforma têm mais acesso ao conteúdo de criadores confiáveis. 
           Os perfis verificados são, geralmente, de criadores de conteúdo que se dedicam a produzir material de qualidade, como páginas de humor ou ensinamentos bíblicos. Com isso, a plataforma se torna um ambiente mais seguro e enriquecedor para todos.
           Recomendamos sempre que criadores de conteúdo verifiquem o perfil para maior alcance.
          </p>

        </section>


      </main>
    </div>
  );
}
