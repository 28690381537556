import { FaPlay, FaVolumeMute, FaVolumeUp } from 'react-icons/fa';
import { useRef, useEffect, useState } from 'react';
import style from "./style.module.css";
import { useNavigate } from 'react-router-dom';
import { AiOutlineComment } from 'react-icons/ai';
import { RiSendPlaneLine } from 'react-icons/ri';
import {FaHeart, FaRegHeart } from 'react-icons/fa';
import { handleSeguirOuNao, obterToken, handleShare } from '../../../global';
import apiUrl from '../../../global';


function PostagemHead({postagem, user,setListaPostagens}){
  const [loadingSeguirDesseguir, setLoadingSeguirDesseguir] = useState(false);
  const postador = postagem.postador;
  const navigate = useNavigate();
  const handleNavigate = (username) => {
      navigate(`/${username}`);
  };
  
  function BotaoSeguir({ user, postagem,setIsFollowing, isFollowing,setListaPostagens }) {
    const [loadingSeguirDesseguir, setLoadingSeguirDesseguir] = useState(false);
 
    const handleSeguir = async () => {
      if (loadingSeguirDesseguir || !user.uid) return; // Evita múltiplas requisições
  
      setLoadingSeguirDesseguir(true);
      try {
        const userIdPostador = postagem.postador.userUid;
        const result = await handleSeguirOuNao(user.uid, userIdPostador);
        if (result) {
          // Atualiza a lista de postagens para caso ache o msm postador, n dê diferença de dados
          setListaPostagens(prevState => 
            prevState.map(postagem => 
              postagem.postador.userUid === userIdPostador
                ? { ...postagem, seguindo: result.segue==true?1:0 } // Atualiza o estado seguindo
                : postagem // Mantém o resto das postagens inalterado
            )
          );



        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingSeguirDesseguir(false);
      }
    };
  
    return (
      <button onClick={handleSeguir} disabled={loadingSeguirDesseguir} className={style.buttonSeguir}>
        {postagem.seguindo ? 'Seguindo' : 'Seguir'}
      </button>
    );
  }

  return(
    <div className={style.postagemHeader}>
      <div className={style.postadorInfo} onClick={()=>handleNavigate(postador.username)}>
        <img className={style.postagemHeaderImg} src={postador.profile_image} alt="User" />
        <p className={style.postagemUsername}>@{postador.username}</p>
      </div>
      {user.uid!==postagem.postador.userUid&& <BotaoSeguir user={user} postagem={postagem} setListaPostagens={setListaPostagens}/>}
    </div>
  );
}

function PostagemInteracoes({postagem,userId,setListaPostagens,setShowModalPostagemUnicaHandler,setPostagemSelecionadaDados, handlerModalDeletePostagem}) {
  const postagemId = postagem.id;
  async function handleLike(){
      const acao = postagem.curtido?"descurtir":"curtir";

      try {
        const token = await obterToken();
        const response = await fetch(`${apiUrl}/curtirPostagem`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}` // Envia o token no cabeçalho
          },
            body: JSON.stringify({ postagemId, acao }) // Envia os dados no corpo da requisição
        });
        const data = await response.json();


        const success = data.success; //Nova qtd de curtidas
       
        if(success){
          // Atualiza a lista de postagens da página home
          setListaPostagens(prevLista => 
            prevLista.map(posts => 
                posts.id === postagemId
                ? {
                    ...posts,
                    quantidadeDeCurtidas: posts.quantidadeDeCurtidas + (!postagem.curtido==true?1:-1),
                    curtido: !posts.curtido 
                  }
                : posts
            )
          );
        }

  
  
      } catch (error) {
        console.error('Erro ao curtir/descurtir postagem:', error);
      }
  
    }
  function onClickComments(){
      setPostagemSelecionadaDados(postagem);
      setShowModalPostagemUnicaHandler(true);
  }

  return (
      <span className={style.containerInteracoes}>
          <div className={style.interacoes}>

              <span onClick={handleLike} className={style.interacoesIconContainer}>
                  {postagem.curtido?<FaHeart color="red" className={style.icon}/>:<FaRegHeart className={style.icon}/>}
              </span>

              <span onClick={onClickComments} className={style.interacoesIconContainer}>
                <AiOutlineComment className={style.icon} />
              </span>

              <span className={style.interacoesIconContainer} onClick={()=>handleShare(postagem.descricao,postagem.url)}>
                <RiSendPlaneLine className={style.icon} />
              </span>
              
              <span className={style.interacoesIconContainer}>
                <p className={style.icon} onClick={() => handlerModalDeletePostagem(postagem)}>...</p>
              </span>
          </div>
      </span>
  );
}


export default function ShowVideoShorts({ postagem, videoRefs, globalMuteVideo, setGlobalMuteVideo, globalPauseVideo, user, setListaPostagens, setShowModalPostagemUnicaHandler, setPostagemSelecionadaDados,handlerModalDeletePostagem}) {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [progress, setProgress] = useState(0); //Controla progresso do video

  useEffect(() => {
      if (videoRef.current) {
          videoRef.current.muted = globalMuteVideo;
      }
  }, [globalMuteVideo]);

  useEffect(() => {
      if (globalPauseVideo && videoRef.current) {
          setIsPlaying(false);
          videoRef.current.pause();
      }
  }, [globalPauseVideo]);

  useEffect(() => {
      if (videoRef.current) {
          videoRefs.current.push(videoRef.current);
      }
  }, [videoRefs]);

  const toggleMute = () => {
      if (videoRef.current) {
          const newMuteState = !globalMuteVideo;
          setGlobalMuteVideo(newMuteState);
      }
  };

  const togglePlay = () => {
      if (videoRef.current) {
          if (isPlaying) {
              videoRef.current.pause();
          } else {
              videoRef.current.play();
          }
          setIsPlaying(!isPlaying);
      }
  };

  const handleProgressChange = (e) => {
    const video = videoRef.current;
    const newTime = (e.target.value / 100) * video.duration;
    video.currentTime = newTime;
    setProgress(e.target.value);
  };

  
  const handleTimeUpdate = () => {  // Atualiza o progresso da barra enquanto o vídeo está tocando
    const video = videoRef.current;
    const currentProgress = (video.currentTime / video.duration) * 100;
    setProgress(currentProgress);
  };


  return (
      <div className={style.divVideo} key={postagem.id}>
          <video
              onClick={togglePlay}
              ref={videoRef}
              className={style.video}
              src={postagem.video.caminhoImagem}
              preload="metadata"
              muted={globalMuteVideo}
              loop
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              onTimeUpdate={handleTimeUpdate}
          />
          <PostagemHead postagem={postagem} user={user} setListaPostagens={setListaPostagens}/>
          <PostagemInteracoes postagem={postagem} userId={user.uid} setListaPostagens={setListaPostagens} setShowModalPostagemUnicaHandler={setShowModalPostagemUnicaHandler} setPostagemSelecionadaDados={setPostagemSelecionadaDados} handlerModalDeletePostagem={handlerModalDeletePostagem}/>
          <div onClick={togglePlay} className={style.playButton}>
              {!isPlaying && <FaPlay className={style.iconePlay} />}
          </div>
          
         {/* Barra de progresso */}
         {!isPlaying &&
          <div className={style.progressBarContainer}>
              <input
                type="range"
                className={style.progressBar}
                value={progress}
                onChange={handleProgressChange} // Permite ao usuário arrastar a barra
                min="0"
                max="100"
              />
              <div className={style.progressFilled} style={{ width: `${progress}%`}}/>
          </div>
         }

          <div onClick={toggleMute} className={style.muteButton}>
              {globalMuteVideo ? <FaVolumeMute className={style.iconeMute} /> : <FaVolumeUp className={style.iconeMute} />}
          </div>
      </div>
  );
}
