import style from "./index.module.css";
import novo from "../../../Imgs/novo2.png";
import DescricaoComMais from "../../DescricaoComMais/DescricaoComMais";
import ShowUserLittleProfile from "../../ShowUserLittleProfile"
import { calcularTempoDecorrido, obterToken} from "../../../global";
import apiUrl from "../../../global";
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useEffect, useState } from "react";
import thumbnailStatico from "../../../Imgs/XimageThumbnail.png";
import React from 'react';


function onClickThumbnail(url) {
  if (!url) return;

  const params = new URLSearchParams(window.location.search);
  params.set('p', `${url}`);
  const newUrl = window.location.pathname + '?' + params.toString();
  window.history.replaceState({}, '', newUrl);
}

function NotifInteracoes(){
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [atingiuFim, setAtingiuFim] = useState(false);
  const [loadMore, setLoadMore] = useState(false); 
  const [notificacoesData, setNotificacoesData] = useState([]);
    //Obtendo notificacoes
    useEffect(() => {
      setLoadMore(true);
    }, []);

    useEffect(() => { //Obtendo novas notificacoes iniciais
      if (loadMore === true) {
        obterNotificacoes();
      }
  
      async function obterNotificacoes() {
        const token = await obterToken();
        try {
          
          const response = await fetch(`${apiUrl}/obterNotificacoesInteracao`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
              offset,
              limit,
            }),
          });
  
  
          if (!response.ok) {
            const errorMessage = `Erro: ${response.status} ${response.statusText}`;
            console.error(errorMessage);
            throw new Error(errorMessage);
          }
          const result = await response.json();
          if (response.status === 200) {
            if(result.notifications){
              if (result.notifications.length < limit) {
                setAtingiuFim(true);
              }
            }else{
              setAtingiuFim(true);
            }
  
            setOffset((prevState) => prevState + limit);
            if (result.notifications) {
              console.log(result.notifications);
              console.log(result.notifications[0].comentarioId);
              setNotificacoesData((prevPostagens) => [...prevPostagens, ...result.notifications]);
            }

          }
        } catch (error) {
          console.log("Erro pegar notificacoes");
          throw error;
        } finally {
          setLoadMore(false);
          console.log('Pegou dados com sucesso*****');
        }
      }
    }, [loadMore]);

    function comentarioIdShow(notificacao){ //Alguem comentou em uma postagem sua
      const tempo = calcularTempoDecorrido(notificacao.data);
      const actorUser = notificacao.actorUser.nome + " ";
      const thumbnail = notificacao.comentario.thumbnail;
      return(
        <div className={style.flexContainer}>
          <div className={style.tituloNot}>
            <p className={style.nomeDataNot}> 
              {actorUser} comentou no seu post - {tempo}
              <span className={style.iconNotific}> 
                {notificacao.notificacaoNova && <img src={novo} className={style.novo}/>}
              </span>
            </p>
          </div>
            
          <div className={style.corpoNot}> {/*Flex row*/}
              <ShowUserLittleProfile user={notificacao.actorUser} />
              <div className={style.thumbnailContainer} onClick={() => onClickThumbnail(notificacao.comentario.url)}>
              <img src={thumbnail} className={style.thumbnail}/>
            </div>
          </div>
        
          <div className={style.bottom}>
              <div className={style.descricaoPostagem}>
                <DescricaoComMais descricao={notificacao.comentario.comentario} limite="80" />
              </div>
          </div>
        </div>
      )
    }
    function curtidapostagemShow(notificacao){ //Alguem comentou em uma postagem sua
      const tempo = calcularTempoDecorrido(notificacao.data);
      const actorUser = notificacao.actorUser.nome + " ";
      const thumbnail = notificacao.curtida_postagem.thumbnail;
      const url = notificacao.curtida_postagem.url;
      return(
        <div className={style.flexContainer}>
          <div className={style.tituloNot}>
            <p className={style.nomeDataNot}> 
              {actorUser} curtiu seu post - {tempo}
              <span className={style.iconNotific}> 
                {notificacao.notificacaoNova && <img src={novo} className={style.novo}/>}
              </span>
            </p>
          </div>
            
          <div className={style.corpoNot}> {/*Flex row*/}
              <ShowUserLittleProfile user={notificacao.actorUser} />
              <div className={style.thumbnailContainer} onClick={() => onClickThumbnail(url)}>
              <img src={thumbnail} className={style.thumbnail}/>
            </div>
          </div>
        
        </div>
      )
    }
    function curtidaComentarioShow(notificacao){ //Alguem comentou em uma postagem sua
      const tempo = calcularTempoDecorrido(notificacao.data);
      const actorUser = notificacao.actorUser.nome + " ";
      const thumbnail = notificacao.curtida_comentario.thumbnail;
      const url = notificacao.curtida_comentario.url;
      return(
        <div className={style.flexContainer}>
          <div className={style.tituloNot}>
            <p className={style.nomeDataNot}> 
              {actorUser} curtiu seu comentario - {tempo}
              <span className={style.iconNotific}> 
                {notificacao.notificacaoNova && <img src={novo} className={style.novo}/>}
              </span>
            </p>
          </div>
            
          <div className={style.corpoNot}> {/*Flex row*/}
              <ShowUserLittleProfile user={notificacao.actorUser} />
              <div className={style.thumbnailContainer} onClick={() => onClickThumbnail(url)}>
              <img src={thumbnail} className={style.thumbnail}/>
            </div>
          </div>
        </div>
      )
    }
    function replyIdShow(notificacao){ //Alguem comentou em uma postagem sua
      const tempo = calcularTempoDecorrido(notificacao.data);
      const actorUser = notificacao.actorUser.nome + " ";
      const thumbnail = notificacao.reply.thumbnail;
      return(
        <div className={style.flexContainer}>
          <div className={style.tituloNot}>
            <p className={style.nomeDataNot}> 
              {actorUser} respondeu você - {tempo}
              <span className={style.iconNotific}> 
                {notificacao.notificacaoNova && <img src={novo} className={style.novo}/>}
              </span>
            </p>
          </div>
            
          <div className={style.corpoNot}> {/*Flex row*/}
              <ShowUserLittleProfile user={notificacao.actorUser} />
              <div className={style.thumbnailContainer} onClick={() => onClickThumbnail(notificacao.reply.url)}>
              <img src={thumbnail} className={style.thumbnail}/>
            </div>
          </div>
        
          <div className={style.bottom}>
              <div className={style.descricaoPostagem}>
                <DescricaoComMais descricao={notificacao.reply.comentario} limite="80" />
              </div>
          </div>
        </div>
      )
    }
   

    return(
      <span>
        {!notificacoesData || notificacoesData.length === 0 ? (
          loadMore == true? 
            <p className={style.noNotifications}>Carregando...</p>
            :
            <p className={style.noNotifications}>Nenhuma notificação</p>
          
          
        ) : (
          <>

            <div className={style.notificationItem}>
              {notificacoesData?.map(( notificacao ) => (
                <span key={notificacao.id}>
                  {
                  notificacao.comentarioId? comentarioIdShow(notificacao):
                  notificacao.curtida_postagemId ? curtidapostagemShow(notificacao):
                  notificacao.curtida_comentarioId ? curtidaComentarioShow(notificacao):
                  notificacao.replyId && replyIdShow(notificacao)
                  
                  }
                </span>
              ))}
            </div>

            {!atingiuFim ? (
              <p className={style.showmore} onClick={() => setLoadMore(true)}>
                Mostrar mais
              </p>
            ):
            <p className={style.msgNoMore}>Sem mais notificações</p>
            }
          </>
        )}
      </span>
    )

}

function NotifDenuncias(){
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [atingiuFim, setAtingiuFim] = useState(false);
  const [loadMore, setLoadMore] = useState(false); 
  const [notificacoesData, setNotificacoesData] = useState([]);
    //Obtendo notificacoes
    useEffect(() => {
      setLoadMore(true);
    }, []);

    useEffect(() => { //Obtendo novas notificacoes iniciais
      if (loadMore === true) {
        obterNotificacoes();
      }
  
      async function obterNotificacoes() {
        const token = await obterToken();
        try {
          
          const response = await fetch(`${apiUrl}/obterNotificacoesDenuncia`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
              offset,
              limit,
            }),
          });
  
          
          if (!response.ok) {
            const errorMessage = `Erro ao obter notificações:`;
            console.error(errorMessage);
          }
          const result = await response.json();
          if (response.status === 200) {
            if(result.notifications){
              if (result.notifications.length < limit) {
                setAtingiuFim(true);
              }
            }else{
              setAtingiuFim(true);
            }
            setOffset((prevState) => prevState + limit);
            console.log("inserindo dados na variavel: ", result.notifications);

            if (result.notifications) {
              setNotificacoesData((prevPostagens) => [...prevPostagens, ...result.notifications]);
            }

          }
        } catch (error) {
          throw error;
        } finally {
          setLoadMore(false);
          console.log('Pegou dados com sucesso*****');
        }
      }
    }, [loadMore]);

   
    function denunciaShow(notificacao){ //Alguem comentou em uma postagem sua
        const tempo = calcularTempoDecorrido(notificacao.data);
        const url = notificacao.versaoPostagem.postagem.url || notificacao.versaoComent.postagem.url;
        const thumbnail = notificacao.versaoPostagem.postagem.thumbnail || notificacao.versaoComent.postagem.thumbnail || thumbnailStatico; //Img statica caso n tenha thumbnail pra postagem
        let userDenunciadoNome = (notificacao.denunciadoUser.nome + " " + notificacao.denunciadoUser.sobrenome) || "usuário do hevelim";
        const status = notificacao.versaoPostagem.statusDenuncia || notificacao.versaoComent.statusDenuncia;

        function ImprimirdenuncieiAlguem(){ //Vc denunciou a postagem de alguém
          let mensagem;
          switch (status) {
            case 'liberado':
              mensagem = 'Obrigado por contribuir com nossa comunidade ao realizar a denúncia. Após uma análise cuidadosa, verificamos que o conteúdo não se enquadra em uma violação das nossas diretrizes no momento. Contudo, um moderador irá avaliar novamente a postagem para um resultado mais preciso. Agradecemos seu envolvimento e estamos sempre atentos para manter a plataforma segura para todos. Fique com Deus 🙏';
              break;
            case 'warning':
              mensagem = 'Sua denúncia foi aceita e a postagem foi retirada do público por violar nossas diretrizes. Ela ainda pode ser vista no perfil pessoal do autor. Paz 🙏';
              break;
            case 'excluirpost':
              mensagem = `Sua denúncia foi aceita e a postagem de ${userDenunciadoNome} foi removida por violar nossa política de privacidade. Obrigado por ajudar a manter nossa rede segura. Fique com Deus 🙏`;
              break;
            case 'baniruser':
              mensagem = `Sua denúncia foi aceita e o perfil ${userDenunciadoNome} foi banido da rede social Hevelim por conta de sua denúncia e/ou de outros. Obrigado por contribuir para uma rede mais segura. Essa notificação sumirá em alguns dias.`;
              break;
            default:
              mensagem = 'Status da denúncia desconhecido.';
          }
          return(
            <div>
              <div className={style.corpoNot}> {/*Flex row*/}
                {
                  status !="baniruser"?
                  <>
                    <ShowUserLittleProfile user={notificacao.denunciadoUser} />
                    <div className={style.thumbnailContainer} onClick={() => onClickThumbnail(url)}>
                      <img src={thumbnail} className={style.thumbnail}/>
                    </div>
                  </>
                  :
                  <>
                    <ShowUserLittleProfile user={notificacao.denunciadoUser} />
                  </>
                }
                
                
              </div>
            
              <div className={style.bottom}>
                  <div className={style.descricaoPostagem}>
                    <span className={style.mensagemDenuncia}>
                    <DescricaoComMais descricao={mensagem} limite="80" />
                    </span>
                  </div>
              </div>
            </div>
          )
        }
        function ImprimiralguemMeDenunciou(){ //Alguém denunciou sua postagem
          let mensagem;
          //N precisa de liberado pq foi bloqueado de chegar aq, e nem d banido pq... bom, o usuario q iria ver foi banido.
          switch (status) {
              case 'warning':
                mensagem = 'Sua postagem foi movida de global para apenas amigos/seguidores. Isso pode acontecer em caso de postagens que violam nossa política de privacidade, como campanha política, ou até mesmo postagens que devem serem postadas no perfil pessoal em vez do global, como vendas, por exemplo.';
                break;
              case "excluirpost":
                mensagem = 'Uma postagem sua foi removida por violar nossa política de privacidade. Para saber mais sobre os motivos e como evitar futuras violações, acesse: hevelim.com/privacidade.';
                break;
              default:
                mensagem = 'Status da denúncia desconhecido.';
          }
          return(
            <div>
              <div className={style.corpoNot}> {/*Flex row*/}
                <span/>
                <div className={style.thumbnailContainer} onClick={() => onClickThumbnail(url)}>
                  {
                    notificacao.statusDenuncia!="excluirpost"?
                    <img src={thumbnail} className={style.thumbnail}/>
                    :
                    <img src={thumbnailStatico} className={style.thumbnail}/>
                  }
                </div>
              </div>
            
              <div className={style.bottom}>
                  <div className={style.descricaoPostagem}>
                    <span className={style.mensagemDenuncia}>
                      <DescricaoComMais descricao={mensagem} limite="80" />
                    </span>
                  </div>
              </div>
            </div>
          )
        }

        function ImprimirdenuncieiAlguemVersãoComent(){
          let comentario = notificacao.versaoComent.comentario;
          let mensagem;
          switch (status) {
            case 'liberado':
              mensagem = `Obrigado por contribuir com nossa comunidade ao realizar a denúncia. Após uma análise cuidadosa, verificamos que o comentário não se enquadra em uma violação das nossas diretrizes no momento. Contudo, um moderador irá avaliar novamente o comentário para um resultado mais preciso. Agradecemos seu envolvimento e estamos sempre atentos para manter a plataforma segura para todos. Fique com Deus 🙏. Comentário: ${comentario}`;
              break;
            case 'warning':
              mensagem = `Sua denúncia foi aceita e o comentário de ${userDenunciadoNome} foi retirada do público por violar nossas diretrizes. Ele ainda pode ser vista no perfil pessoal do autor. Paz 🙏. Comentário: ${comentario}`;
              break;
            case 'excluirComent':
              mensagem = `Sua denúncia foi aceita e o comentário de ${userDenunciadoNome} foi removido por violar nossa política de privacidade. Obrigado por ajudar a manter nossa rede segura. Fique com Deus 🙏. Comentário: ${comentario}`;
              break;
            case 'baniruser':
              mensagem = `Sua denúncia foi aceita e o perfil ${userDenunciadoNome} foi banido da rede social Hevelim por conta de sua denúncia e/ou de outros. Obrigado por contribuir para uma rede mais segura. Essa notificação sumirá em alguns dias.`;
              break;
            default:
              mensagem = 'Status da denúncia desconhecido.';
          }
          return(
            <div>
              <div className={style.corpoNot}> {/*Flex row*/}
                {
                  status !="baniruser"?
                  <>
                    <ShowUserLittleProfile user={notificacao.denunciadoUser} />
                    <div className={style.thumbnailContainer} onClick={() => onClickThumbnail(url)}>
                      <img src={thumbnail} className={style.thumbnail}/>
                    </div>
                  </>
                  :
                  <>
                    <ShowUserLittleProfile user={notificacao.denunciadoUser} />
                  </>
                }
                
                
              </div>
            
              <div className={style.bottom}>
                  <div className={style.descricaoPostagem}>
                    <span className={style.mensagemDenuncia}>
                    <DescricaoComMais descricao={mensagem} limite="80" />
                    </span>
                  </div>
              </div>
            </div>
          )
        }
        function ImprimiralguemMeDenunciouVersãoComent(){ //Alguém denunciou sua postagem
          let comentario = notificacao.versaoComent.comentario;
          let mensagem;
          switch (status) {
            //N precisa de liberado pq foi bloqueado de chegar aq, e nem d banido pq... bom, o usuario q iria ver foi banido.
              case 'warning':
                mensagem = `Parece que um comentário seu pode não ser muito apropriado para a plataforma. Poderia revisá-lo? Agradecemos a compreensão. Comentário: ${comentario}`;
                break;
              case "excluirComent":
                mensagem = 'Um comentário seu foi removido por violar nossa política de privacidade. Para saber mais sobre os motivos e como evitar futuras violações, acesse: hevelim.com/privacidade.';
              break;
              default:
                mensagem = `Status da denúncia desconhecido.`;
          }
          return(
            <div>
              <div className={style.corpoNot}> {/*Flex row*/}
                <span/>
                <div className={style.thumbnailContainer} onClick={() => onClickThumbnail(url)}>
                  {
                    notificacao.statusDenuncia!="excluirpost"?
                    <img src={thumbnail} className={style.thumbnail}/>
                    :
                    <img src={thumbnailStatico} className={style.thumbnail}/>
                  }
                </div>
              </div>
            
              <div className={style.bottom}>
                  <div className={style.descricaoPostagem}>
                    <span className={style.mensagemDenuncia}>
                      <DescricaoComMais descricao={mensagem} limite="80" />
                    </span>
                  </div>
              </div>
            </div>
          )
        }
       

        return(
          <div className={style.flexContainer}>
            { 
              //Só aparece os dados de terceiros, nunca os seus. Então se existe delator, alguém denunciou vc
              //Se existe denunciado, vc denunciou alguém
              notificacao.denunciadoUser.username && notificacao.versaoPostagem.denunciaId? //Vc denunciou alguém e veio a resposta da denuncia para você.
              <>
                <div className={style.tituloNot}>
                    <p className={style.nomeDataNot}> 
                      Denuncia de postagem - {tempo}
                      <span className={style.iconNotific}> 
                        {notificacao.notificacaoNova && <img src={novo} className={style.novo}/>}
                      </span>
                    </p>
                </div>
                {ImprimirdenuncieiAlguem()}
              </>
              
              :
              notificacao.delatorUser.uid && notificacao.versaoPostagem.denunciaId && notificacao.versaoPostagem.statusDenuncia!="liberado" ? //Alguém te denunciou. Diferente de liberado pq ngm precisa receber q foi denunciado e liberado, dps eu vejo se tiro no db isso
              <>
                <div className={style.tituloNot}>
                    <p className={style.nomeDataNot}> 
                      Denuncia de postagem - {tempo}
                      <span className={style.iconNotific}> 
                        {notificacao.notificacaoNova && <img src={novo} className={style.novo}/>}
                      </span>
                    </p>
                </div>
                {ImprimiralguemMeDenunciou()}
              </>

              :
              
              notificacao.denunciadoUser.username && notificacao.versaoComent.comentario && notificacao.versaoComent.statusDenuncia!=null? //Vc denunciou alguém vrsão coment e veio a resposta da denuncia para você.
              <>
                <div className={style.tituloNot}>
                    <p className={style.nomeDataNot}> 
                      Denuncia de comentário - {tempo}
                      <span className={style.iconNotific}> 
                        {notificacao.notificacaoNova && <img src={novo} className={style.novo}/>}
                      </span>
                    </p>
                </div>
                {ImprimirdenuncieiAlguemVersãoComent()}
              </>
               :
               notificacao.delatorUser.uid && notificacao.versaoComent.comentario && notificacao.versaoComent.statusDenuncia!="liberado" && //Alguém te denunciou. Diferente de liberado pq ngm precisa receber q foi denunciado e liberado, dps eu vejo se tiro no db isso
               <>
                 <div className={style.tituloNot}>
                     <p className={style.nomeDataNot}> 
                       Denuncia de postagem - {tempo}
                       <span className={style.iconNotific}> 
                         {notificacao.notificacaoNova && <img src={novo} className={style.novo}/>}
                       </span>
                     </p>
                 </div>
                 {ImprimiralguemMeDenunciouVersãoComent()}
               </>
              
            }
          </div>
        )
    }

    return(
      <span>
        {!notificacoesData || notificacoesData.length === 0 ? (
          loadMore == true? 
            <p className={style.noNotifications}>Carregando...</p>
            :
            <p className={style.noNotifications}>Nenhuma notificação</p>
          
          
        ) : (
          <div>

            <div className={style.notificationItem}>

              { notificacoesData?.map(( notificacao ) => (
                  <span key={notificacao.data}>
                    {
                    denunciaShow(notificacao)
                    }
                  </span>
                ))
              }
            </div>
            

            {!atingiuFim ? (
              <p className={style.showmore} onClick={() => setLoadMore(true)}>
                Mostrar mais
              </p>
            ):
            <p className={style.msgNoMore}>Sem mais notificações</p>
            }
          </div>
        )}
      </span>
    )

}

function NotifSeguidores(){
  return(
    <div></div>
  );
}

export default function NotificacoesModal({onClickNotificacoes, notificacoesHandler}) {
    const [selectedButton, setSelectedButton] = useState("interacoes");

    useEffect(() => { //Ao voltar no celular, fecha o modal em vez de ir pra página anterior

      window.history.pushState(null, '', window.location.href);// Adiciona uma entrada ao histórico quando o modal é aberto  
      
      const handlePopState = () => {
        onClickNotificacoes();
      };
  
      window.addEventListener('popstate', handlePopState);
  
      // Limpeza do listener ao desmontar o modal
      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }, [notificacoesHandler]); //Handler como dependencia (pois é ao abrir e ao fechar modal)


    return (
      <div className={style.searchModal}>
        <span className={style.iconVoltar}><AiOutlineArrowLeft size={24} onClick={()=>onClickNotificacoes()}/></span>
        <p className={style.searchModalTitle}>Notificações</p>
        <div className={style.grupoButton}>
        <button
                className={selectedButton === 'interacoes' ? style.botaoTopSelected : style.botaoTop}
                onClick={() => setSelectedButton('interacoes')}
            >
                Interações
            </button>
            {/* <button
                className={selectedButton === 'seguidores' ? style.botaoTopSelected : style.botaoTop}
                onClick={() => setSelectedButton('seguidores')}
            >
                Seguidores
            </button> */}
            <button
                className={selectedButton === 'denuncias' ? style.botaoTopSelected : style.botaoTop}
                onClick={() => setSelectedButton('denuncias')}
            >
                Denúncias
            </button>
        </div>

        {
          selectedButton=="interacoes" ? <NotifInteracoes/>
          :
          selectedButton=="denuncias"? <NotifDenuncias/>
          :
          selectedButton=="seguidores"&&<NotifSeguidores/>

        }
      </div>
    );
}